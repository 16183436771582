@import '../../variables.scss';

.wrapper-pay {
    border-radius: 20px;
    -webkit-box-shadow: -3px 6px 19px -6px rgba(0,0,0,0.68);
    -moz-box-shadow: -3px 6px 19px -6px rgba(148, 75, 75, 0.68);
    box-shadow: -3px 6px 19px -6px rgba(0,0,0,0.68);
    max-width: 800px;
    margin:2rem auto;
    padding: 3rem;
    background:rgba(255,255,255,0.5);
}

.form-group {
    margin:auto;

    .form-control {
        margin:1rem 0;
        background:#f5f5f5;
        border: none;
        padding:1rem;
        min-height: 60px;
    }
}
