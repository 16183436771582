@import '../../variables.scss';
@import '../../mixins.scss';

.wrapper-cargando {
    margin:0 auto;
    text-align: center;

    h3 {
        margin-bottom: 0;
    }

    i {
        font-size: 40px;
        animation: spin 2s infinite linear;
        display: block;

        @keyframes spin {

            from {
                @include rotate(0deg);
            }

            to {
                @include rotate(360deg);
            }
        }
    }
}