@import '../../variables.scss';
@import '../../mixins.scss';

.formularioUsuario {
    border-radius: 20px;
    -webkit-box-shadow: -3px 6px 19px -6px rgba(0,0,0,0.68);
    -moz-box-shadow: -3px 6px 19px -6px rgba(0,0,0,0.68);
    box-shadow: -3px 6px 19px -6px rgba(0,0,0,0.68);
    max-width: 700px;
    margin:5rem auto;
    padding: 3rem;
    background-color: rgba(white, 0.5);

    label {
        text-align: left;
        display: block;
    }

    h2 {
        margin-bottom: 1rem;
    }

    .btn-info {
        background:$color-secundario;
        border:none;
        outline: none;
        margin-left: auto;
        margin-right: 0;
    }

    @media(max-width:414px) {
        margin:1rem;
        padding: 2rem;
    }
}

.container-login {
    border-radius: 20px;
    -webkit-box-shadow: -3px 6px 19px -6px rgba(0,0,0,0.68);
    -moz-box-shadow: -3px 6px 19px -6px rgba(0,0,0,0.68);
    box-shadow: -3px 6px 19px -6px rgba(0,0,0,0.68);
    max-width: 500px;
    margin:5rem auto;
    padding: 3rem;
    background-color: rgba(white, 1);

    form {
        h1 {
            margin-bottom:2rem;
            color:$color-primario;
        }
        .form-group {
            text-align: left;

            label {
                font-weight: bolder;
            }
        }

        a {
            color:$color-terceario;
        }
    }

    .registro-container {
        display: flex;
        justify-content: flex-end;
        
        a {
            color:$color-terceario;
        }

    }

    @media(max-width:414px) {
        margin:1rem;
        padding: 2rem;
    }
}

.ad {
    max-width: 60%;
    margin: 0 auto;
    background-color: rgba($color-primario, 0.5);
    padding: 2rem;
    border-radius: 20px;
    margin-top: 2rem;
    position: relative;

    i {
        background:white;
        padding: 0.5rem;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        font-size: 30px;
        position: absolute;
        top:0;
        left: 50%;
        text-align: center;
        @include translate(-50%, -50%)
    }
}

