@import '../../mixins';

.circular-wrapper {

    & > * {
        margin:0 2rem;
        overflow:hidden;
    }
}

