.wrapper-blocks {
    margin:0 1rem;
    width: 23%;
    overflow: hidden;
    text-align: center;

    img {
        max-width: 100px;
        width: 100%;
        height: auto;
        border-radius: 20px;
        margin:auto;
        text-align: center;
        padding:1rem;
        background:rgba(0,177,173, .3);
    }

    .text-block {
        margin-top:1rem;
    }

}