@import "~bootstrap/scss/bootstrap";
@import './variables.scss';
@import './mixins.scss';

@import url('https://fonts.googleapis.com/css2?family=Courgette&family=Lexend:wght@300;400;500;600&family=Montez&family=Playfair+Display:wght@700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap');

body {
  padding: 0;
  margin:0;
  background:$color-secundario;
}

p {
  color:#5b5b5b;
  font-family: $f-text;
}

ul {
  list-style: none;
  padding: 0;
  margin:0;
}

li {
  color:#5b5b5b;
  font-family: $f-text;
}


 strong {
  color:$color-secundario;
  font-family: $f-text;
}

button {
  padding: 1rem 2rem;
  font-family: $f-text;
  color:white;
  background:$color-secundario;
  outline:none;
  box-shadow: none;
  border: none;
  border-radius: 30px!important;
}

section {
  min-height: 250px;
}

input[type=submit] {
  background:$color-secundario;
  border:none;

  &:hover {
    background:black;
  }
}

h1, h2,h3,h4,h5 {
  color:$color-secundario;
  font-weight: 600;
  font-family: $f-titulos;
  
}

.container {
  background:white;
}

.icon-arrow_left, .icon-arrow_right {
  color:white;
  opacity: 1;
  background:#0A2239;
  display: block;
  bottom: 60%;
  padding: 0.5rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position:absolute;
  z-index: 30000;
}



.swal2-title {
  position: relative;
  max-width: 100%;
  margin: 0 0 .4em;
  padding: 0;
  display: block!important;
  color: #595959;
  font-size: 1.875em;
  font-weight: 600;
  text-align: center;
  text-transform: none;
  word-wrap: break-word;
}

a.btn-success {
  background-color: $color-secundario!important;
}

.btn-success {
  background:$color-primario;
  border: none !important;
  outline: none!important;
  box-shadow:none!important;

  &:hover {
    background: $color-primario!important;
  }

  &:focus {
    background:$color-primario;
    border-color: $color-primario;
  }
}

.container-fluid {
  position: relative;
  padding: 2rem 0 !important;
  min-height: 850px;
}

.container {
  max-width: 1500px!important;
  padding: 3rem 0!important;
  min-height: 70vh;

  @media(max-width:414px) {
    padding: 1rem 0!important;
  }
}

.wrapper-error {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
}

.msg-error {
  background:rgb(216, 116, 116);
  color:white;
  padding: 1rem;
  max-width: 350px;
  top:0px;
  border-radius: 10px;
  font-size:20px;

  @-webkit-keyframes slide-in-top {
      0% {
        -webkit-transform: translateY(-1000px);
                transform: translateY(-1000px);
        opacity: 0;
      }
      100% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
        opacity: 1;
      }
    }
    @keyframes slide-in-top {
      0% {
        -webkit-transform: translateY(-1000px);
                transform: translateY(-1000px);
        opacity: 0;
      }
      100% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
        opacity: 1;
      }
    }
    
    
}

video {
  &:focus {
    outline: none;
  }
}

.destacado {
  background:#00B1AD;
  color:white;
  display:block;
  width:auto;
}

.text-destacado {
  color:$color-primario;
  font-weight: bold;
}

.wrapper-images {
  overflow: hidden;
  height: 330px;
  margin:0 auto;
  position: relative;
  margin-bottom: 3rem;

  img {
      position: absolute;
      top: -160px;
      width:1600px;
  }
}

h3 {
  width: 100%;
  margin:2rem 0!important;
  padding:2rem 0;
  text-align: center;
  font-size: 3rem!important;

  @media(max-width:414px) {
    font-size: 30px!important;
    margin: 0 !important;
    padding: 1rem!important;
  }
}

.container-blocks {
  display: flex;
  flex-wrap: wrap;
  margin: auto;

    .wrapper-blocks {
      width:30%;

      @media(max-width:768px) {
        width:90%;
        margin:auto;
      }
    }

}

.container-cards {
  display: flex;
  flex-wrap: wrap;
  
}


img.icon {
  width: 30px;
}

.container-contact {
  position:relative;
  width: 100%;
  background-size: 500px;
  margin:2rem 0;
  background-color: white;
  background-position: center center;
  padding: 3rem 5rem;
  flex-wrap: wrap;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  
  .text-contact {
    background:rgba(255,255,255, 0.8);
    border-radius: 20px;
    width: 60%;
    margin:2rem auto;
    padding:2rem;

    @media(max-width:768px) {
      width: 90%;
    }

    @media(max-width:414px) {
      padding: 0.5rem;
    }
  
    h2 {
      text-align: center;
      text-transform: uppercase;
    }
  
    ul {
      display: flex;
      justify-content: center;
      max-width: 450px;
      padding: 0 2rem;
      margin:auto;
  
      li {
        a {
          color:black;
          text-align: center;
          max-width: 150px;
          overflow: hidden;
          display: block;
  
          &:hover {
            text-decoration: none;
            -moz-transform: scale(1.04);
            -webkit-transform: scale(1.04);
            -o-transform: scale(1.04);
            -ms-transform: scale(1.04);
            transform: scale(1.04);
            @include transition(all, 0.1s, linear);
          }
        }
        .wrapper-img {
          background:white;
          width: 50px;
          height: 50px;
          padding: 1rem;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50px;
          margin:auto;
        }
  
        img {
          min-width: 50px;
        }
      }
    }
  
    i { 
      display: flex;
      align-items:center;
      justify-content: center;
      margin:auto;
      font-size: 30px;
      text-align: center;
      padding: 1rem;
      background:white;
      width:50px;
      height: 50px;
      border-radius: 50px;
    }

    .wrapper-contact {
      text-align: center;

      h4 {
        margin: 2rem 0;
      }

      i {
        display: inline-block;
        margin:0;
        font-size: 16px;
        margin-right: 1rem;
      }

    }
  
  
  }

  @media(max-width:414px) {
    padding: 0;
    background-image: none!important;
  }

}

.carousel-control-prev, .carousel-control-next {

  @media(max-width:414px) {
    display: none!important;
  }

}

.carousel-inner {
  padding: 0.5rem!important;
}

.carousel {
  @media(max-width:414px) {
    height: 700px!important;
  }
}

.politica-privacidad {
  padding: 3rem;

  h4 {
    margin:2rem 0;
    padding: 0;
  }

  h1 {
    margin-bottom: 2rem;
  }
}


.cookie-wrapper {
  padding: 1rem;
  text-align: center;
  background:#B2E7E6;
  position: fixed;
  bottom: 0;

  .button-wrapper {
    margin-top: 1rem;

    button {
      margin-left: 2rem;
    }
  }
}

.error-container {
  text-align: center;

  .back-to-home {
    display: block;
    padding: 1rem;
    text-align: center;
    background-color: $color-primario;
    width: 200px;
    margin: 20px auto;
    color:white;
    border-radius: 10px;
  }
}




