@mixin box-shadow($top, $left, $blur, $color, $inset: false) {
    @if $inset {
      -webkit-box-shadow:inset $top $left $blur $color;
      -moz-box-shadow:inset $top $left $blur $color;
      box-shadow:inset $top $left $blur $color;
    } @else {
      -webkit-box-shadow: $top $left $blur $color;
      -moz-box-shadow: $top $left $blur $color;
      box-shadow: $top $left $blur $color;
    }
  }

  @mixin translate($medidaX, $medidaY) {
    -moz-transform: translateX($medidaX) translateY($medidaY);
    -webkit-transform: translateX($medidaX) translateY($medidaY);
    -o-transform: translateX($medidaX) translateY($medidaY);
    -ms-transform: translateX($medidaX) translateY($medidaY);
    transform: translateX($medidaX) translateY($medidaY);
}

@mixin transition($propiedad, $duracion, $funcion) {
    -webkit-transition: $propiedad $duracion $funcion;
    -moz-transition: $propiedad $duracion $funcion;
    -ms-transition: $propiedad $duracion $funcion;
    -o-transition: $propiedad $duracion $funcion;
    transition: $propiedad $duracion $funcion;
}

@mixin scale($escala) {
    -moz-transform: scale($escala);
    -webkit-transform: scale($escala);
    -o-transform: scale($escala);
    -ms-transform: scale($escala);
    transform: scale($escala);
}

@mixin rotate($degree) {
  -moz-transform: rotate($degree);
  -webkit-transform: rotate($degree);
  -o-transform: rotate($degree);
  -ms-transform: rotate($degree);
  transform: rotate($degree);
}