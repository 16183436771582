@font-face {
    font-family: 'icomoon';
    src: url('fonts/icomoon.eot?qwdff1');
    src: url('fonts/icomoon.eot?qwdff1#iefix') format('embedded-opentype'), url('fonts/icomoon.ttf?qwdff1') format('truetype'), url('fonts/icomoon.woff?qwdff1') format('woff'), url('fonts/icomoon.svg?qwdff1#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


.icon-infojobs:before {
    content: "\e900";
    color: #167db7;
}

.icon-seguridad-social:before {
    content: "\e901";
}

.icon-doc-dotacion:before {
    content: "\e902";
}

.icon-unidad-organizativa:before {
    content: "\e903";
}

.icon-doc-irpf:before {
    content: "\e904";
}

.icon-doc-t10:before {
    content: "\e905";
}

.icon-tatuaje:before {
    content: "\e906";
}

.icon-piercing:before {
    content: "\e907";
}

.icon-agrupacion-pedidos:before {
    content: "\e908";
}

.icon-hacienda:before {
    content: "\e909";
}

.icon-doc-tercero:before {
    content: "\e90a";
}

.icon-doc-finiquito:before {
    content: "\e90b";
}

.icon-doc-intracomunitaria:before {
    content: "\e90c";
}

.icon-doc-reservasii:before {
    content: "\e90d";
}

.icon-doc-rectificativa:before {
    content: "\e90e";
}

.icon-doc-prorrogacpd:before {
    content: "\e90f";
}

.icon-doc-factura:before {
    content: "\e910";
}

.icon-doc-nomina:before {
    content: "\e911";
}

.icon-doc-pro:before {
    content: "\e912";
}

.icon-doc-cl:before {
    content: "\e913";
}

.icon-doc-prorrogacl:before {
    content: "\e914";
}

.icon-doc-cpd:before {
    content: "\e915";
}

.icon-no-repeat:before {
    content: "\e916";
}

.icon-calendar-year:before {
    content: "\e917";
}

.icon-calendar-month:before {
    content: "\e918";
}

.icon-ipad:before {
    content: "\e919";
}

.icon-otp:before {
    content: "\e91a";
}

.icon-mark-calendar2:before {
    content: "\e91b";
}

.icon-copy-file:before {
    content: "\e91c";
}

.icon-uniE904:before {
    content: "\e91d";
}

.icon-uniE905:before {
    content: "\e91e";
}

.icon-uniE906:before {
    content: "\e91f";
}

.icon-uniE907:before {
    content: "\e920";
}

.icon-repeat-new:before {
    content: "\e921";
}

.icon-uniE908:before {
    content: "\e922";
}

.icon-notdef:before {
    content: "\e923";
}

.icon-cv:before {
    content: "\e924";
}

.icon-underage:before {
    content: "\e925";
}

.icon-uniE90D:before {
    content: "\e926";
}

.icon-uniE90E:before {
    content: "\e927";
}

.icon-uniE90F:before {
    content: "\e928";
}

.icon-uniE910:before {
    content: "\e929";
}

.icon-uniE911:before {
    content: "\e92a";
}

.icon-uniE912:before {
    content: "\e92b";
}

.icon-uniE913:before {
    content: "\e92c";
}

.icon-handwritting:before {
    content: "\e92d";
}

.icon-smile-sad:before {
    content: "\e92e";
}

.icon-email-otp:before {
    content: "\e92f";
}

.icon-accept:before {
    content: "\e930";
}

.icon-file-2:before {
    content: "\e931";
}

.icon-contract-1:before {
    content: "\e932";
}

.icon-www:before {
    content: "\e934";
}

.icon-smile-happy:before {
    content: "\e933";
}

.icon-digital-sign:before {
    content: "\e935";
}

.icon-digital:before {
    content: "\e936";
}

.icon-calendar-line:before {
    content: "\e937";
}

.icon-proyecto-facturacion:before {
    content: "\e938";
}

.icon-centro-trabajo:before {
    content: "\e939";
}

.icon-publicaciones:before {
    content: "\e93a";
}

.icon-oferta-empleo:before {
    content: "\e93b";
}

.icon-casting:before {
    content: "\e93c";
}

.icon-invitacion:before {
    content: "\e93d";
}

.icon-cobro-factura:before {
    content: "\e93e";
}

.icon-editar-documento:before {
    content: "\e93f";
}

.icon-home:before {
    content: "\e940";
}

.icon-home2:before {
    content: "\e941";
}

.icon-home3:before {
    content: "\e942";
}

.icon-office:before {
    content: "\e943";
}

.icon-newspaper:before {
    content: "\e944";
}

.icon-pencil:before {
    content: "\e945";
}

.icon-play:before {
    content: "\e946";
}

.icon-film:before {
    content: "\e947";
}

.icon-video-camera:before {
    content: "\e948";
}

.icon-dice:before {
    content: "\e949";
}

.icon-pacman:before {
    content: "\e94a";
}

.icon-spades:before {
    content: "\e94b";
}

.icon-file-empty:before {
    content: "\e94c";
}

.icon-files-empty:before {
    content: "\e94d";
}

.icon-file-text2:before {
    content: "\e94e";
}

.icon-file-picture:before {
    content: "\e94f";
}

.icon-file-music:before {
    content: "\e950";
}

.icon-file-play:before {
    content: "\e951";
}

.icon-price-tags:before {
    content: "\e952";
}

.icon-barcode:before {
    content: "\e953";
}

.icon-qrcode:before {
    content: "\e954";
}

.icon-ticket:before {
    content: "\e955";
}

.icon-cart:before {
    content: "\e956";
}

.icon-coin-dollar:before {
    content: "\e957";
}

.icon-location2:before {
    content: "\e958";
}

.icon-compass:before {
    content: "\e959";
}

.icon-compass2:before {
    content: "\e95a";
}

.icon-map:before {
    content: "\e95b";
}

.icon-map2:before {
    content: "\e95c";
}

.icon-history:before {
    content: "\e95d";
}

.icon-tablet:before {
    content: "\e95e";
}

.icon-tv:before {
    content: "\e95f";
}

.icon-drawer:before {
    content: "\e960";
}

.icon-drawer2:before {
    content: "\e961";
}

.icon-box-add:before {
    content: "\e962";
}

.icon-box-remove:before {
    content: "\e963";
}

.icon-doc-empty:before {
    content: "\e964";
}

.icon-bubbles:before {
    content: "\e96c";
}

.icon-bubbles2:before {
    content: "\e96d";
}

.icon-bubble2:before {
    content: "\e96e";
}

.icon-bubbles3:before {
    content: "\e96f";
}

.icon-bubbles4:before {
    content: "\e970";
}

.icon-user:before {
    content: "\e971";
}

.icon-spinner5:before {
    content: "\e97e";
}

.icon-spinner6:before {
    content: "\e97f";
}

.icon-spinner7:before {
    content: "\e980";
}

.icon-spinner8:before {
    content: "\e981";
}

.icon-spinner9:before {
    content: "\e982";
}

.icon-spinner10:before {
    content: "\e983";
}

.icon-unlocked:before {
    content: "\e990";
}

.icon-wrench:before {
    content: "\e991";
}

.icon-equalizer:before {
    content: "\e992";
}

.icon-equalizer2:before {
    content: "\e993";
}

.icon-cog:before {
    content: "\e994";
}

.icon-cogs:before {
    content: "\e995";
}

.icon-mug:before {
    content: "\e9a2";
}

.icon-spoon-knife:before {
    content: "\e9a3";
}

.icon-leaf:before {
    content: "\e9a4";
}

.icon-rocket:before {
    content: "\e9a5";
}

.icon-meter:before {
    content: "\e9a6";
}

.icon-meter2:before {
    content: "\e9a7";
}

.icon-shield:before {
    content: "\e9b4";
}

.icon-power:before {
    content: "\e9b5";
}

.icon-switch:before {
    content: "\e9b6";
}

.icon-power-cord:before {
    content: "\e9b7";
}

.icon-clipboard:before {
    content: "\e9b8";
}

.icon-list-numbered:before {
    content: "\e9b9";
}

.icon-upload2:before {
    content: "\e9c6";
}

.icon-download3:before {
    content: "\e9c7";
}

.icon-upload3:before {
    content: "\e9c8";
}

.icon-sphere:before {
    content: "\e9c9";
}

.icon-earth:before {
    content: "\e9ca";
}

.icon-link:before {
    content: "\e9cb";
}

.icon-star-half:before {
    content: "\e9d8";
}

.icon-star-full:before {
    content: "\e9d9";
}

.icon-heart:before {
    content: "\e9da";
}

.icon-heart-broken:before {
    content: "\e9db";
}

.icon-man:before {
    content: "\e9dc";
}

.icon-woman:before {
    content: "\e9dd";
}

.icon-grin2:before {
    content: "\e9ea";
}

.icon-cool:before {
    content: "\e9eb";
}

.icon-cool2:before {
    content: "\e9ec";
}

.icon-angry:before {
    content: "\e9ed";
}

.icon-angry2:before {
    content: "\e9ee";
}

.icon-evil:before {
    content: "\e9ef";
}

.icon-wondering2:before {
    content: "\e9fc";
}

.icon-sleepy:before {
    content: "\e9fd";
}

.icon-sleepy2:before {
    content: "\e9fe";
}

.icon-frustrated:before {
    content: "\e9ff";
}

.icon-frustrated2:before {
    content: "\ea00";
}

.icon-crying:before {
    content: "\ea01";
}

.icon-blocked:before {
    content: "\ea0e";
}

.icon-cross:before {
    content: "\ea0f";
}

.icon-checkmark:before {
    content: "\ea10";
}

.icon-checkmark2:before {
    content: "\ea11";
}

.icon-spell-check:before {
    content: "\ea12";
}

.icon-enter:before {
    content: "\ea13";
}

.icon-forward3:before {
    content: "\ea20";
}

.icon-first:before {
    content: "\ea21";
}

.icon-last:before {
    content: "\ea22";
}

.icon-previous2:before {
    content: "\ea23";
}

.icon-next2:before {
    content: "\ea24";
}

.icon-eject:before {
    content: "\ea25";
}

.icon-arrow-up:before {
    content: "\ea32";
}

.icon-arrow-up-right:before {
    content: "\ea33";
}

.icon-arrow-right:before {
    content: "\ea34";
}

.icon-arrow-down-right:before {
    content: "\ea35";
}

.icon-arrow-down:before {
    content: "\ea36";
}

.icon-arrow-down-left:before {
    content: "\ea37";
}

.icon-circle-left:before {
    content: "\ea44";
}

.icon-tab:before {
    content: "\ea45";
}

.icon-move-up:before {
    content: "\ea46";
}

.icon-move-down:before {
    content: "\ea47";
}

.icon-sort-alpha-asc:before {
    content: "\ea48";
}

.icon-sort-alpha-desc:before {
    content: "\ea49";
}

.icon-radio-unchecked:before {
    content: "\ea56";
}

.icon-crop:before {
    content: "\ea57";
}

.icon-make-group:before {
    content: "\ea58";
}

.icon-ungroup:before {
    content: "\ea59";
}

.icon-scissors:before {
    content: "\ea5a";
}

.icon-filter:before {
    content: "\ea5b";
}

.icon-page-break:before {
    content: "\ea68";
}

.icon-superscript:before {
    content: "\ea69";
}

.icon-subscript:before {
    content: "\ea6a";
}

.icon-superscript2:before {
    content: "\ea6b";
}

.icon-subscript2:before {
    content: "\ea6c";
}

.icon-text-color:before {
    content: "\ea6d";
}

.icon-paragraph-justify:before {
    content: "\ea7a";
}

.icon-indent-increase:before {
    content: "\ea7b";
}

.icon-indent-decrease:before {
    content: "\ea7c";
}

.icon-share:before {
    content: "\ea7d";
}

.icon-new-tab:before {
    content: "\ea7e";
}

.icon-embed:before {
    content: "\ea7f";
}

.icon-google-plus2:before {
    content: "\ea8c";
}

.icon-google-plus3:before {
    content: "\ea8d";
}

.icon-hangouts:before {
    content: "\ea8e";
}

.icon-google-drive:before {
    content: "\ea8f";
}

.icon-facebook:before {
    content: "\ea90";
}

.icon-facebook2:before {
    content: "\ea91";
}

.icon-youtube2:before {
    content: "\ea9e";
}

.icon-twitch:before {
    content: "\ea9f";
}

.icon-vimeo:before {
    content: "\eaa0";
}

.icon-vimeo2:before {
    content: "\eaa1";
}

.icon-lanyrd:before {
    content: "\eaa2";
}

.icon-onedrive:before {
    content: "\eaaf";
}

.icon-github:before {
    content: "\eab0";
}

.icon-npm:before {
    content: "\eab1";
}

.icon-basecamp:before {
    content: "\eab2";
}

.icon-trello:before {
    content: "\eab3";
}

.icon-wordpress:before {
    content: "\eab4";
}

.icon-windows:before {
    content: "\eac1";
}

.icon-windows8:before {
    content: "\eac2";
}

.icon-soundcloud:before {
    content: "\eac3";
}

.icon-soundcloud2:before {
    content: "\eac4";
}

.icon-skype:before {
    content: "\eac5";
}

.icon-reddit:before {
    content: "\eac6";
}

.icon-xing:before {
    content: "\ead3";
}

.icon-xing2:before {
    content: "\ead4";
}

.icon-flattr:before {
    content: "\ead5";
}

.icon-foursquare:before {
    content: "\ead6";
}

.icon-yelp:before {
    content: "\ead7";
}

.icon-paypal:before {
    content: "\ead8";
}

.icon-html-five2:before {
    content: "\eae5";
}

.icon-css3:before {
    content: "\eae6";
}

.icon-git:before {
    content: "\eae7";
}

.icon-codepen:before {
    content: "\eae8";
}

.icon-svg:before {
    content: "\eae9";
}

.icon-IcoMoon:before {
    content: "\eaea";
}

.icon-glass:before {
    content: "\f000";
}

.icon-music:before {
    content: "\f001";
}

.icon-search:before {
    content: "\f002";
}

.icon-envelope:before {
    content: "\f003";
}

.icon-heart1:before {
    content: "\f004";
}

.icon-star:before {
    content: "\f005";
}

.icon-star_empty:before {
    content: "\f006";
}

.icon-user1:before {
    content: "\f007";
}

.icon-film1:before {
    content: "\f008";
}

.icon-th_large:before {
    content: "\f009";
}

.icon-th:before {
    content: "\f00a";
}

.icon-th_list:before {
    content: "\f00b";
}

.icon-ok:before {
    content: "\f00c";
}

.icon-remove:before {
    content: "\f00d";
}

.icon-zoom_in:before {
    content: "\f00e";
}

.icon-glass-martini:before {
    content: "\f00f";
}

.icon-zoom_out:before {
    content: "\f010";
}

.icon-off:before {
    content: "\f011";
}

.icon-signal:before {
    content: "\f012";
}

.icon-cog1:before {
    content: "\f013";
}

.icon-trash:before {
    content: "\f014";
}

.icon-home1:before {
    content: "\f015";
}

.icon-file_alt:before {
    content: "\f016";
}

.icon-time:before {
    content: "\f017";
}

.icon-road:before {
    content: "\f018";
}

.icon-download_alt:before {
    content: "\f019";
}

.icon-download:before {
    content: "\f01a";
}

.icon-upload:before {
    content: "\f01b";
}

.icon-inbox:before {
    content: "\f01c";
}

.icon-play_circle:before {
    content: "\f01d";
}

.icon-repeat:before {
    content: "\f01e";
}

.icon-music1:before {
    content: "\f01f";
}

.icon-search1:before {
    content: "\f020";
}

.icon-refresh:before {
    content: "\f021";
}

.icon-list_alt:before {
    content: "\f022";
}

.icon-lock:before {
    content: "\f023";
}

.icon-flag:before {
    content: "\f024";
}

.icon-headphones:before {
    content: "\f025";
}

.icon-volume_off:before {
    content: "\f026";
}

.icon-volume_down:before {
    content: "\f027";
}

.icon-volume_up:before {
    content: "\f028";
}

.icon-qrcode1:before {
    content: "\f029";
}

.icon-barcode1:before {
    content: "\f02a";
}

.icon-tag:before {
    content: "\f02b";
}

.icon-tags:before {
    content: "\f02c";
}

.icon-book:before {
    content: "\f02d";
}

.icon-bookmark:before {
    content: "\f02e";
}

.icon-print:before {
    content: "\f02f";
}

.icon-camera:before {
    content: "\f030";
}

.icon-font:before {
    content: "\f031";
}

.icon-bold:before {
    content: "\f032";
}

.icon-italic:before {
    content: "\f033";
}

.icon-text_height:before {
    content: "\f034";
}

.icon-text_width:before {
    content: "\f035";
}

.icon-align_left:before {
    content: "\f036";
}

.icon-align_center:before {
    content: "\f037";
}

.icon-align_right:before {
    content: "\f038";
}

.icon-align_justify:before {
    content: "\f039";
}

.icon-list:before {
    content: "\f03a";
}

.icon-indent_left:before {
    content: "\f03b";
}

.icon-indent_right:before {
    content: "\f03c";
}

.icon-facetime_video:before {
    content: "\f03d";
}

.icon-picture:before {
    content: "\f03e";
}

.icon-heart11:before {
    content: "\f03f";
}

.icon-pencil1:before {
    content: "\f040";
}

.icon-map_marker:before {
    content: "\f041";
}

.icon-adjust:before {
    content: "\f042";
}

.icon-tint:before {
    content: "\f043";
}

.icon-edit:before {
    content: "\f044";
}

.icon-share1:before {
    content: "\f045";
}

.icon-check:before {
    content: "\f046";
}

.icon-move:before {
    content: "\f047";
}

.icon-step_backward:before {
    content: "\f048";
}

.icon-fast_backward:before {
    content: "\f049";
}

.icon-backward:before {
    content: "\f04a";
}

.icon-play1:before {
    content: "\f04b";
}

.icon-pause:before {
    content: "\f04c";
}

.icon-stop:before {
    content: "\f04d";
}

.icon-forward:before {
    content: "\f04e";
}

.icon-star1:before {
    content: "\f04f";
}

.icon-fast_forward:before {
    content: "\f050";
}

.icon-step_forward:before {
    content: "\f051";
}

.icon-eject1:before {
    content: "\f052";
}

.icon-chevron_left:before {
    content: "\f053";
}

.icon-chevron_right:before {
    content: "\f054";
}

.icon-plus_sign:before {
    content: "\f055";
}

.icon-minus_sign:before {
    content: "\f056";
}

.icon-remove_sign:before {
    content: "\f057";
}

.icon-ok_sign:before {
    content: "\f058";
}

.icon-question_sign:before {
    content: "\f059";
}

.icon-info_sign:before {
    content: "\f05a";
}

.icon-screenshot:before {
    content: "\f05b";
}

.icon-remove_circle:before {
    content: "\f05c";
}

.icon-ok_circle:before {
    content: "\f05d";
}

.icon-ban_circle:before {
    content: "\f05e";
}

.icon-user11:before {
    content: "\f05f";
}

.icon-arrow_left:before {
    content: "\f060";
}

.icon-arrow_right:before {
    content: "\f061";
}

.icon-arrow_up:before {
    content: "\f062";
}

.icon-arrow_down:before {
    content: "\f063";
}

.icon-share_alt:before {
    content: "\f064";
}

.icon-resize_full:before {
    content: "\f065";
}

.icon-resize_small:before {
    content: "\f066";
}

.icon-plus:before {
    content: "\f067";
}

.icon-minus:before {
    content: "\f068";
}

.icon-asterisk:before {
    content: "\f069";
}

.icon-exclamation_sign:before {
    content: "\f06a";
}

.icon-gift:before {
    content: "\f06b";
}

.icon-leaf1:before {
    content: "\f06c";
}

.icon-fire:before {
    content: "\f06d";
}

.icon-eye_open:before {
    content: "\f06e";
}

.icon-film11:before {
    content: "\f06f";
}

.icon-eye_close:before {
    content: "\f070";
}

.icon-warning_sign:before {
    content: "\f071";
}

.icon-plane:before {
    content: "\f072";
}

.icon-calendar:before {
    content: "\f073";
}

.icon-random:before {
    content: "\f074";
}

.icon-comment:before {
    content: "\f075";
}

.icon-magnet:before {
    content: "\f076";
}

.icon-chevron_up:before {
    content: "\f077";
}

.icon-chevron_down:before {
    content: "\f078";
}

.icon-retweet:before {
    content: "\f079";
}

.icon-shopping_cart:before {
    content: "\f07a";
}

.icon-folder_close:before {
    content: "\f07b";
}

.icon-folder_open:before {
    content: "\f07c";
}

.icon-resize_vertical:before {
    content: "\f07d";
}

.icon-resize_horizontal:before {
    content: "\f07e";
}

.icon-th-large:before {
    content: "\f07f";
}

.icon-bar_chart:before {
    content: "\f080";
}

.icon-twitter_sign:before {
    content: "\f081";
}

.icon-facebook_sign:before {
    content: "\f082";
}

.icon-camera_retro:before {
    content: "\f083";
}

.icon-key:before {
    content: "\f084";
}

.icon-cogs1:before {
    content: "\f085";
}

.icon-comments:before {
    content: "\f086";
}

.icon-thumbs_up_alt:before {
    content: "\f087";
}

.icon-thumbs_down_alt:before {
    content: "\f088";
}

.icon-star_half:before {
    content: "\f089";
}

.icon-heart_empty:before {
    content: "\f08a";
}

.icon-signout:before {
    content: "\f08b";
}

.icon-linkedin_sign:before {
    content: "\f08c";
}

.icon-pushpin:before {
    content: "\f08d";
}

.icon-external_link:before {
    content: "\f08e";
}

.icon-th1:before {
    content: "\f08f";
}

.icon-signin:before {
    content: "\f090";
}

.icon-trophy:before {
    content: "\f091";
}

.icon-github_sign:before {
    content: "\f092";
}

.icon-upload_alt:before {
    content: "\f093";
}

.icon-lemon:before {
    content: "\f094";
}

.icon-phone:before {
    content: "\f095";
}

.icon-check_empty:before {
    content: "\f096";
}

.icon-bookmark_empty:before {
    content: "\f097";
}

.icon-phone_sign:before {
    content: "\f098";
}

.icon-twitter:before {
    content: "\f099";
}

.icon-facebook1:before {
    content: "\f09a";
}

.icon-github1:before {
    content: "\f09b";
}

.icon-unlock:before {
    content: "\f09c";
}

.icon-credit_card:before {
    content: "\f09d";
}

.icon-rss:before {
    content: "\f09e";
}

.icon-th-list:before {
    content: "\f09f";
}

.icon-hdd:before {
    content: "\f0a0";
}

.icon-bullhorn:before {
    content: "\f0a1";
}

.icon-bell:before {
    content: "\f0a2";
}

.icon-certificate:before {
    content: "\f0a3";
}

.icon-hand_right:before {
    content: "\f0a4";
}

.icon-hand_left:before {
    content: "\f0a5";
}

.icon-hand_up:before {
    content: "\f0a6";
}

.icon-hand_down:before {
    content: "\f0a7";
}

.icon-circle_arrow_left:before {
    content: "\f0a8";
}

.icon-circle_arrow_right:before {
    content: "\f0a9";
}

.icon-circle_arrow_up:before {
    content: "\f0aa";
}

.icon-circle_arrow_down:before {
    content: "\f0ab";
}

.icon-globe:before {
    content: "\f0ac";
}

.icon-wrench1:before {
    content: "\f0ad";
}

.icon-tasks:before {
    content: "\f0ae";
}

.icon-check1:before {
    content: "\f0af";
}

.icon-filter1:before {
    content: "\f0b0";
}

.icon-briefcase:before {
    content: "\f0b1";
}

.icon-fullscreen:before {
    content: "\f0b2";
}

.icon-times:before {
    content: "\f0b3";
}

.icon-search-plus:before {
    content: "\f0b4";
}

.icon-search-minus:before {
    content: "\f0b5";
}

.icon-power-off:before {
    content: "\f0b6";
}

.icon-signal1:before {
    content: "\f0b7";
}

.icon-cog11:before {
    content: "\f0b8";
}

.icon-home11:before {
    content: "\f0b9";
}

.icon-clock:before {
    content: "\f0ba";
}

.icon-road1:before {
    content: "\f0bb";
}

.icon-download1:before {
    content: "\f0bc";
}

.icon-inbox1:before {
    content: "\f0bd";
}

.icon-redo:before {
    content: "\f0be";
}

.icon-sync:before {
    content: "\f0bf";
}

.icon-group:before {
    content: "\f0c0";
}

.icon-link1:before {
    content: "\f0c1";
}

.icon-cloud:before {
    content: "\f0c2";
}

.icon-beaker:before {
    content: "\f0c3";
}

.icon-cut:before {
    content: "\f0c4";
}

.icon-copy:before {
    content: "\f0c5";
}

.icon-paper_clip:before {
    content: "\f0c6";
}

.icon-save:before {
    content: "\f0c7";
}

.icon-sign_blank:before {
    content: "\f0c8";
}

.icon-reorder:before {
    content: "\f0c9";
}

.icon-ul:before {
    content: "\f0ca";
}

.icon-ol:before {
    content: "\f0cb";
}

.icon-strikethrough:before {
    content: "\f0cc";
}

.icon-underline:before {
    content: "\f0cd";
}

.icon-table:before {
    content: "\f0ce";
}

.icon-list-alt:before {
    content: "\f0cf";
}

.icon-magic:before {
    content: "\f0d0";
}

.icon-truck:before {
    content: "\f0d1";
}

.icon-pinterest:before {
    content: "\f0d2";
}

.icon-pinterest_sign:before {
    content: "\f0d3";
}

.icon-google_plus_sign:before {
    content: "\f0d4";
}

.icon-google_plus:before {
    content: "\f0d5";
}

.icon-money:before {
    content: "\f0d6";
}

.icon-caret_down:before {
    content: "\f0d7";
}

.icon-caret_up:before {
    content: "\f0d8";
}

.icon-caret_left:before {
    content: "\f0d9";
}

.icon-caret_right:before {
    content: "\f0da";
}

.icon-columns:before {
    content: "\f0db";
}

.icon-sort:before {
    content: "\f0dc";
}

.icon-sort_down:before {
    content: "\f0dd";
}

.icon-sort_up:before {
    content: "\f0de";
}

.icon-lock1:before {
    content: "\f0df";
}

.icon-envelope_alt:before {
    content: "\f0e0";
}

.icon-linkedin:before {
    content: "\f0e1";
}

.icon-undo:before {
    content: "\f0e2";
}

.icon-legal:before {
    content: "\f0e3";
}

.icon-dashboard:before {
    content: "\f0e4";
}

.icon-comment_alt:before {
    content: "\f0e5";
}

.icon-comments_alt:before {
    content: "\f0e6";
}

.icon-bolt:before {
    content: "\f0e7";
}

.icon-sitemap:before {
    content: "\f0e8";
}

.icon-umbrella:before {
    content: "\f0e9";
}

.icon-paste:before {
    content: "\f0ea";
}

.icon-light_bulb:before {
    content: "\f0eb";
}

.icon-exchange:before {
    content: "\f0ec";
}

.icon-cloud_download:before {
    content: "\f0ed";
}

.icon-cloud_upload:before {
    content: "\f0ee";
}

.icon-flag1:before {
    content: "\f0ef";
}

.icon-user_md:before {
    content: "\f0f0";
}

.icon-stethoscope:before {
    content: "\f0f1";
}

.icon-suitcase:before {
    content: "\f0f2";
}

.icon-bell_alt:before {
    content: "\f0f3";
}

.icon-coffee:before {
    content: "\f0f4";
}

.icon-food:before {
    content: "\f0f5";
}

.icon-file_text_alt:before {
    content: "\f0f6";
}

.icon-building:before {
    content: "\f0f7";
}

.icon-hospital:before {
    content: "\f0f8";
}

.icon-ambulance:before {
    content: "\f0f9";
}

.icon-medkit:before {
    content: "\f0fa";
}

.icon-fighter_jet:before {
    content: "\f0fb";
}

.icon-beer:before {
    content: "\f0fc";
}

.icon-h_sign:before {
    content: "\f0fd";
}

.icon-f0fe:before {
    content: "\f0fe";
}

.icon-headphones1:before {
    content: "\f0ff";
}

.icon-double_angle_left:before {
    content: "\f100";
}

.icon-double_angle_right:before {
    content: "\f101";
}

.icon-double_angle_up:before {
    content: "\f102";
}

.icon-double_angle_down:before {
    content: "\f103";
}

.icon-angle_left:before {
    content: "\f104";
}

.icon-angle_right:before {
    content: "\f105";
}

.icon-angle_up:before {
    content: "\f106";
}

.icon-angle_down:before {
    content: "\f107";
}

.icon-desktop:before {
    content: "\f108";
}

.icon-laptop:before {
    content: "\f109";
}

.icon-tablet1:before {
    content: "\f10a";
}

.icon-mobile_phone:before {
    content: "\f10b";
}

.icon-circle_blank:before {
    content: "\f10c";
}

.icon-quote_left:before {
    content: "\f10d";
}

.icon-quote_right:before {
    content: "\f10e";
}

.icon-volume-off:before {
    content: "\f10f";
}

.icon-spinner:before {
    content: "\f110";
}

.icon-circle:before {
    content: "\f111";
}

.icon-reply:before {
    content: "\f112";
}

.icon-github_alt:before {
    content: "\f113";
}

.icon-folder_close_alt:before {
    content: "\f114";
}

.icon-folder_open_alt:before {
    content: "\f115";
}

.icon-volume-down:before {
    content: "\f116";
}

.icon-volume-up:before {
    content: "\f117";
}

.icon-smile:before {
    content: "\f118";
}

.icon-frown:before {
    content: "\f119";
}

.icon-meh:before {
    content: "\f11a";
}

.icon-gamepad:before {
    content: "\f11b";
}

.icon-keyboard:before {
    content: "\f11c";
}

.icon-flag_alt:before {
    content: "\f11d";
}

.icon-flag_checkered:before {
    content: "\f11e";
}

.icon-qrcode11:before {
    content: "\f11f";
}

.icon-terminal:before {
    content: "\f120";
}

.icon-code:before {
    content: "\f121";
}

.icon-reply_all:before {
    content: "\f122";
}

.icon-star_half_empty:before {
    content: "\f123";
}

.icon-location_arrow:before {
    content: "\f124";
}

.icon-crop1:before {
    content: "\f125";
}

.icon-code_fork:before {
    content: "\f126";
}

.icon-unlink:before {
    content: "\f127";
}

.icon-question:before {
    content: "\f128";
}

.icon-_279:before {
    content: "\f129";
}

.icon-exclamation:before {
    content: "\f12a";
}

.icon-superscript1:before {
    content: "\f12b";
}

.icon-subscript1:before {
    content: "\f12c";
}

.icon-_283:before {
    content: "\f12d";
}

.icon-puzzle_piece:before {
    content: "\f12e";
}

.icon-barcode11:before {
    content: "\f12f";
}

.icon-microphone:before {
    content: "\f130";
}

.icon-microphone_off:before {
    content: "\f131";
}

.icon-shield1:before {
    content: "\f132";
}

.icon-calendar_empty:before {
    content: "\f133";
}

.icon-fire_extinguisher:before {
    content: "\f134";
}

.icon-rocket1:before {
    content: "\f135";
}

.icon-maxcdn:before {
    content: "\f136";
}

.icon-chevron_sign_left:before {
    content: "\f137";
}

.icon-chevron_sign_right:before {
    content: "\f138";
}

.icon-chevron_sign_up:before {
    content: "\f139";
}

.icon-chevron_sign_down:before {
    content: "\f13a";
}

.icon-html5:before {
    content: "\f13b";
}

.icon-css31:before {
    content: "\f13c";
}

.icon-anchor:before {
    content: "\f13d";
}

.icon-unlock_alt:before {
    content: "\f13e";
}

.icon-tag1:before {
    content: "\f13f";
}

.icon-bullseye:before {
    content: "\f140";
}

.icon-ellipsis_horizontal:before {
    content: "\f141";
}

.icon-ellipsis_vertical:before {
    content: "\f142";
}

.icon-_303:before {
    content: "\f143";
}

.icon-play_sign:before {
    content: "\f144";
}

.icon-ticket1:before {
    content: "\f145";
}

.icon-minus_sign_alt:before {
    content: "\f146";
}

.icon-check_minus:before {
    content: "\f147";
}

.icon-level_up:before {
    content: "\f148";
}

.icon-level_down:before {
    content: "\f149";
}

.icon-check_sign:before {
    content: "\f14a";
}

.icon-edit_sign:before {
    content: "\f14b";
}

.icon-_312:before {
    content: "\f14c";
}

.icon-share_sign:before {
    content: "\f14d";
}

.icon-compass1:before {
    content: "\f14e";
}

.icon-tags1:before {
    content: "\f14f";
}

.icon-collapse:before {
    content: "\f150";
}

.icon-collapse_top:before {
    content: "\f151";
}

.icon-_317:before {
    content: "\f152";
}

.icon-eur:before {
    content: "\f153";
}

.icon-gbp:before {
    content: "\f154";
}

.icon-usd:before {
    content: "\f155";
}

.icon-inr:before {
    content: "\f156";
}

.icon-jpy:before {
    content: "\f157";
}

.icon-rub:before {
    content: "\f158";
}

.icon-krw:before {
    content: "\f159";
}

.icon-btc:before {
    content: "\f15a";
}

.icon-file:before {
    content: "\f15b";
}

.icon-file_text:before {
    content: "\f15c";
}

.icon-sort_by_alphabet:before {
    content: "\f15d";
}

.icon-_329:before {
    content: "\f15e";
}

.icon-book1:before {
    content: "\f15f";
}

.icon-sort_by_attributes:before {
    content: "\f160";
}

.icon-sort_by_attributes_alt:before {
    content: "\f161";
}

.icon-sort_by_order:before {
    content: "\f162";
}

.icon-sort_by_order_alt:before {
    content: "\f163";
}

.icon-_334:before {
    content: "\f164";
}

.icon-_335:before {
    content: "\f165";
}

.icon-youtube_sign:before {
    content: "\f166";
}

.icon-youtube:before {
    content: "\f167";
}

.icon-xing1:before {
    content: "\f168";
}

.icon-xing_sign:before {
    content: "\f169";
}

.icon-youtube_play:before {
    content: "\f16a";
}

.icon-dropbox:before {
    content: "\f16b";
}

.icon-stackexchange:before {
    content: "\f16c";
}

.icon-instagram:before {
    content: "\f16d";
}

.icon-flickr:before {
    content: "\f16e";
}

.icon-bookmark1:before {
    content: "\f16f";
}

.icon-adn:before {
    content: "\f170";
}

.icon-f171:before {
    content: "\f171";
}

.icon-bitbucket_sign:before {
    content: "\f172";
}

.icon-tumblr:before {
    content: "\f173";
}

.icon-tumblr_sign:before {
    content: "\f174";
}

.icon-long_arrow_down:before {
    content: "\f175";
}

.icon-long_arrow_up:before {
    content: "\f176";
}

.icon-long_arrow_left:before {
    content: "\f177";
}

.icon-long_arrow_right:before {
    content: "\f178";
}

.icon-apple:before {
    content: "\f179";
}

.icon-windows1:before {
    content: "\f17a";
}

.icon-android:before {
    content: "\f17b";
}

.icon-linux:before {
    content: "\f17c";
}

.icon-dribble:before {
    content: "\f17d";
}

.icon-skype1:before {
    content: "\f17e";
}

.icon-print1:before {
    content: "\f17f";
}

.icon-foursquare1:before {
    content: "\f180";
}

.icon-trello1:before {
    content: "\f181";
}

.icon-female:before {
    content: "\f182";
}

.icon-male:before {
    content: "\f183";
}

.icon-gittip:before {
    content: "\f184";
}

.icon-sun:before {
    content: "\f185";
}

.icon-_366:before {
    content: "\f186";
}

.icon-archive:before {
    content: "\f187";
}

.icon-bug:before {
    content: "\f188";
}

.icon-vk:before {
    content: "\f189";
}

.icon-weibo:before {
    content: "\f18a";
}

.icon-renren:before {
    content: "\f18b";
}

.icon-_372:before {
    content: "\f18c";
}

.icon-stack_exchange:before {
    content: "\f18d";
}

.icon-_374:before {
    content: "\f18e";
}

.icon-camera1:before {
    content: "\f18f";
}

.icon-arrow_circle_alt_left:before {
    content: "\f190";
}

.icon-_376:before {
    content: "\f191";
}

.icon-dot_circle_alt:before {
    content: "\f192";
}

.icon-_378:before {
    content: "\f193";
}

.icon-vimeo_square:before {
    content: "\f194";
}

.icon-_380:before {
    content: "\f195";
}

.icon-plus_square_o:before {
    content: "\f196";
}

.icon-_382:before {
    content: "\f197";
}

.icon-_383:before {
    content: "\f198";
}

.icon-_384:before {
    content: "\f199";
}

.icon-_385:before {
    content: "\f19a";
}

.icon-_386:before {
    content: "\f19b";
}

.icon-_387:before {
    content: "\f19c";
}

.icon-_388:before {
    content: "\f19d";
}

.icon-_389:before {
    content: "\f19e";
}

.icon-font1:before {
    content: "\f19f";
}

.icon-uniF1A0:before {
    content: "\f1a0";
}

.icon-f1a1:before {
    content: "\f1a1";
}

.icon-_392:before {
    content: "\f1a2";
}

.icon-_393:before {
    content: "\f1a3";
}

.icon-f1a4:before {
    content: "\f1a4";
}

.icon-_395:before {
    content: "\f1a5";
}

.icon-_396:before {
    content: "\f1a6";
}

.icon-_397:before {
    content: "\f1a7";
}

.icon-_398:before {
    content: "\f1a8";
}

.icon-_399:before {
    content: "\f1a9";
}

.icon-_400:before {
    content: "\f1aa";
}

.icon-f1ab:before {
    content: "\f1ab";
}

.icon-_402:before {
    content: "\f1ac";
}

.icon-_403:before {
    content: "\f1ad";
}

.icon-_404:before {
    content: "\f1ae";
}

.icon-bold1:before {
    content: "\f1af";
}

.icon-uniF1B1:before {
    content: "\f1b0";
}

.icon-_406:before {
    content: "\f1b1";
}

.icon-_407:before {
    content: "\f1b2";
}

.icon-_408:before {
    content: "\f1b3";
}

.icon-_409:before {
    content: "\f1b4";
}

.icon-_410:before {
    content: "\f1b5";
}

.icon-_411:before {
    content: "\f1b6";
}

.icon-_412:before {
    content: "\f1b7";
}

.icon-_413:before {
    content: "\f1b8";
}

.icon-_414:before {
    content: "\f1b9";
}

.icon-_415:before {
    content: "\f1ba";
}

.icon-_416:before {
    content: "\f1bb";
}

.icon-_417:before {
    content: "\f1bc";
}

.icon-_418:before {
    content: "\f1bd";
}

.icon-_419:before {
    content: "\f1be";
}

.icon-italic1:before {
    content: "\f1bf";
}

.icon-uniF1C0:before {
    content: "\f1c0";
}

.icon-uniF1C1:before {
    content: "\f1c1";
}

.icon-_422:before {
    content: "\f1c2";
}

.icon-_423:before {
    content: "\f1c3";
}

.icon-_424:before {
    content: "\f1c4";
}

.icon-_425:before {
    content: "\f1c5";
}

.icon-_426:before {
    content: "\f1c6";
}

.icon-_427:before {
    content: "\f1c7";
}

.icon-_428:before {
    content: "\f1c8";
}

.icon-_429:before {
    content: "\f1c9";
}

.icon-_430:before {
    content: "\f1ca";
}

.icon-_431:before {
    content: "\f1cb";
}

.icon-_432:before {
    content: "\f1cc";
}

.icon-_433:before {
    content: "\f1cd";
}

.icon-_434:before {
    content: "\f1ce";
}

.icon-text-height:before {
    content: "\f1cf";
}

.icon-uniF1D0:before {
    content: "\f1d0";
}

.icon-uniF1D1:before {
    content: "\f1d1";
}

.icon-uniF1D2:before {
    content: "\f1d2";
}

.icon-_438:before {
    content: "\f1d3";
}

.icon-_439:before {
    content: "\f1d4";
}

.icon-uniF1D5:before {
    content: "\f1d5";
}

.icon-uniF1D6:before {
    content: "\f1d6";
}

.icon-uniF1D7:before {
    content: "\f1d7";
}

.icon-_443:before {
    content: "\f1d8";
}

.icon-_444:before {
    content: "\f1d9";
}

.icon-_445:before {
    content: "\f1da";
}

.icon-_446:before {
    content: "\f1db";
}

.icon-_447:before {
    content: "\f1dc";
}

.icon-_448:before {
    content: "\f1dd";
}

.icon-_449:before {
    content: "\f1de";
}

.icon-text-width:before {
    content: "\f1df";
}

.icon-uniF1E0:before {
    content: "\f1e0";
}

.icon-_451:before {
    content: "\f1e1";
}

.icon-_452:before {
    content: "\f1e2";
}

.icon-_453:before {
    content: "\f1e3";
}

.icon-_454:before {
    content: "\f1e4";
}

.icon-_455:before {
    content: "\f1e5";
}

.icon-_456:before {
    content: "\f1e6";
}

.icon-_457:before {
    content: "\f1e7";
}

.icon-_458:before {
    content: "\f1e8";
}

.icon-_459:before {
    content: "\f1e9";
}

.icon-_460:before {
    content: "\f1ea";
}

.icon-_461:before {
    content: "\f1eb";
}

.icon-_462:before {
    content: "\f1ec";
}

.icon-_463:before {
    content: "\f1ed";
}

.icon-_464:before {
    content: "\f1ee";
}

.icon-align-left:before {
    content: "\f1ef";
}

.icon-uniF1F0:before {
    content: "\f1f0";
}

.icon-_466:before {
    content: "\f1f1";
}

.icon-_467:before {
    content: "\f1f2";
}

.icon-f1f3:before {
    content: "\f1f3";
}

.icon-_469:before {
    content: "\f1f4";
}

.icon-_470:before {
    content: "\f1f5";
}

.icon-_471:before {
    content: "\f1f6";
}

.icon-_472:before {
    content: "\f1f7";
}

.icon-_473:before {
    content: "\f1f8";
}

.icon-_474:before {
    content: "\f1f9";
}

.icon-_475:before {
    content: "\f1fa";
}

.icon-_476:before {
    content: "\f1fb";
}

.icon-f1fc:before {
    content: "\f1fc";
}

.icon-_478:before {
    content: "\f1fd";
}

.icon-_479:before {
    content: "\f1fe";
}

.icon-align-center:before {
    content: "\f1ff";
}

.icon-_480:before {
    content: "\f200";
}

.icon-_481:before {
    content: "\f201";
}

.icon-_482:before {
    content: "\f202";
}

.icon-_483:before {
    content: "\f203";
}

.icon-_484:before {
    content: "\f204";
}

.icon-_485:before {
    content: "\f205";
}

.icon-_486:before {
    content: "\f206";
}

.icon-_487:before {
    content: "\f207";
}

.icon-_488:before {
    content: "\f208";
}

.icon-_489:before {
    content: "\f209";
}

.icon-_490:before {
    content: "\f20a";
}

.icon-_491:before {
    content: "\f20b";
}

.icon-_492:before {
    content: "\f20c";
}

.icon-_493:before {
    content: "\f20d";
}

.icon-_494:before {
    content: "\f20e";
}

.icon-align-right:before {
    content: "\f20f";
}

.icon-f210:before {
    content: "\f210";
}

.icon-_496:before {
    content: "\f211";
}

.icon-f212:before {
    content: "\f212";
}

.icon-_498:before {
    content: "\f213";
}

.icon-_499:before {
    content: "\f214";
}

.icon-_500:before {
    content: "\f215";
}

.icon-_501:before {
    content: "\f216";
}

.icon-_502:before {
    content: "\f217";
}

.icon-_503:before {
    content: "\f218";
}

.icon-_504:before {
    content: "\f219";
}

.icon-_505:before {
    content: "\f21a";
}

.icon-_506:before {
    content: "\f21b";
}

.icon-_507:before {
    content: "\f21c";
}

.icon-_508:before {
    content: "\f21d";
}

.icon-_509:before {
    content: "\f21e";
}

.icon-align-justify:before {
    content: "\f21f";
}

.icon-list1:before {
    content: "\f220";
}

.icon-venus:before {
    content: "\f221";
}

.icon-_511:before {
    content: "\f222";
}

.icon-_512:before {
    content: "\f223";
}

.icon-_513:before {
    content: "\f224";
}

.icon-_514:before {
    content: "\f225";
}

.icon-_515:before {
    content: "\f226";
}

.icon-_516:before {
    content: "\f227";
}

.icon-_517:before {
    content: "\f228";
}

.icon-_518:before {
    content: "\f229";
}

.icon-_519:before {
    content: "\f22a";
}

.icon-_520:before {
    content: "\f22b";
}

.icon-_521:before {
    content: "\f22c";
}

.icon-_522:before {
    content: "\f22d";
}

.icon-outdent:before {
    content: "\f22e";
}

.icon-indent:before {
    content: "\f22f";
}

.icon-_525:before {
    content: "\f230";
}

.icon-_526:before {
    content: "\f231";
}

.icon-_527:before {
    content: "\f232";
}

.icon-_528:before {
    content: "\f233";
}

.icon-_529:before {
    content: "\f234";
}

.icon-_530:before {
    content: "\f235";
}

.icon-_531:before {
    content: "\f236";
}

.icon-_532:before {
    content: "\f237";
}

.icon-_533:before {
    content: "\f238";
}

.icon-_534:before {
    content: "\f239";
}

.icon-_535:before {
    content: "\f23a";
}

.icon-_536:before {
    content: "\f23b";
}

.icon-_537:before {
    content: "\f23c";
}

.icon-_538:before {
    content: "\f23d";
}

.icon-_539:before {
    content: "\f23e";
}

.icon-video:before {
    content: "\f23f";
}

.icon-_540:before {
    content: "\f240";
}

.icon-_541:before {
    content: "\f241";
}

.icon-_542:before {
    content: "\f242";
}

.icon-_543:before {
    content: "\f243";
}

.icon-_544:before {
    content: "\f244";
}

.icon-_545:before {
    content: "\f245";
}

.icon-_546:before {
    content: "\f246";
}

.icon-_547:before {
    content: "\f247";
}

.icon-_548:before {
    content: "\f248";
}

.icon-_549:before {
    content: "\f249";
}

.icon-_550:before {
    content: "\f24a";
}

.icon-_551:before {
    content: "\f24b";
}

.icon-_552:before {
    content: "\f24c";
}

.icon-_553:before {
    content: "\f24d";
}

.icon-_554:before {
    content: "\f24e";
}

.icon-image:before {
    content: "\f24f";
}

.icon-_555:before {
    content: "\f250";
}

.icon-_556:before {
    content: "\f251";
}

.icon-_557:before {
    content: "\f252";
}

.icon-_558:before {
    content: "\f253";
}

.icon-_559:before {
    content: "\f254";
}

.icon-_560:before {
    content: "\f255";
}

.icon-_561:before {
    content: "\f256";
}

.icon-_562:before {
    content: "\f257";
}

.icon-_563:before {
    content: "\f258";
}

.icon-_564:before {
    content: "\f259";
}

.icon-_565:before {
    content: "\f25a";
}

.icon-_566:before {
    content: "\f25b";
}

.icon-_567:before {
    content: "\f25c";
}

.icon-_568:before {
    content: "\f25d";
}

.icon-_569:before {
    content: "\f25e";
}

.icon-map-marker:before {
    content: "\f25f";
}

.icon-f260:before {
    content: "\f260";
}

.icon-f261:before {
    content: "\f261";
}

.icon-_572:before {
    content: "\f262";
}

.icon-f263:before {
    content: "\f263";
}

.icon-_574:before {
    content: "\f264";
}

.icon-_575:before {
    content: "\f265";
}

.icon-_576:before {
    content: "\f266";
}

.icon-_577:before {
    content: "\f267";
}

.icon-_578:before {
    content: "\f268";
}

.icon-_579:before {
    content: "\f269";
}

.icon-_580:before {
    content: "\f26a";
}

.icon-_581:before {
    content: "\f26b";
}

.icon-_582:before {
    content: "\f26c";
}

.icon-_583:before {
    content: "\f26d";
}

.icon-_584:before {
    content: "\f26e";
}

.icon-adjust1:before {
    content: "\f26f";
}

.icon-_585:before {
    content: "\f270";
}

.icon-_586:before {
    content: "\f271";
}

.icon-_587:before {
    content: "\f272";
}

.icon-_588:before {
    content: "\f273";
}

.icon-_589:before {
    content: "\f274";
}

.icon-_590:before {
    content: "\f275";
}

.icon-_591:before {
    content: "\f276";
}

.icon-_592:before {
    content: "\f277";
}

.icon-_593:before {
    content: "\f278";
}

.icon-_594:before {
    content: "\f279";
}

.icon-_595:before {
    content: "\f27a";
}

.icon-_596:before {
    content: "\f27b";
}

.icon-_597:before {
    content: "\f27c";
}

.icon-_598:before {
    content: "\f27d";
}

.icon-f27e:before {
    content: "\f27e";
}

.icon-tint1:before {
    content: "\f27f";
}

.icon-uniF280:before {
    content: "\f280";
}

.icon-uniF281:before {
    content: "\f281";
}

.icon-_602:before {
    content: "\f282";
}

.icon-_603:before {
    content: "\f283";
}

.icon-_604:before {
    content: "\f284";
}

.icon-uniF285:before {
    content: "\f285";
}

.icon-uniF286:before {
    content: "\f286";
}

.icon-_607:before {
    content: "\f287";
}

.icon-_608:before {
    content: "\f288";
}

.icon-_609:before {
    content: "\f289";
}

.icon-_610:before {
    content: "\f28a";
}

.icon-_611:before {
    content: "\f28b";
}

.icon-_612:before {
    content: "\f28c";
}

.icon-_613:before {
    content: "\f28d";
}

.icon-_614:before {
    content: "\f28e";
}

.icon-edit1:before {
    content: "\f28f";
}

.icon-_615:before {
    content: "\f290";
}

.icon-_616:before {
    content: "\f291";
}

.icon-_617:before {
    content: "\f292";
}

.icon-_618:before {
    content: "\f293";
}

.icon-_619:before {
    content: "\f294";
}

.icon-_620:before {
    content: "\f295";
}

.icon-_621:before {
    content: "\f296";
}

.icon-_622:before {
    content: "\f297";
}

.icon-_623:before {
    content: "\f298";
}

.icon-_624:before {
    content: "\f299";
}

.icon-_625:before {
    content: "\f29a";
}

.icon-_626:before {
    content: "\f29b";
}

.icon-_627:before {
    content: "\f29c";
}

.icon-_628:before {
    content: "\f29d";
}

.icon-_629:before {
    content: "\f29e";
}

.icon-step-backward:before {
    content: "\f29f";
}

.icon-uniF2A0:before {
    content: "\f2a0";
}

.icon-uniF2A1:before {
    content: "\f2a1";
}

.icon-uniF2A2:before {
    content: "\f2a2";
}

.icon-uniF2A3:before {
    content: "\f2a3";
}

.icon-uniF2A4:before {
    content: "\f2a4";
}

.icon-uniF2A5:before {
    content: "\f2a5";
}

.icon-uniF2A6:before {
    content: "\f2a6";
}

.icon-uniF2A7:before {
    content: "\f2a7";
}

.icon-uniF2A8:before {
    content: "\f2a8";
}

.icon-uniF2A9:before {
    content: "\f2a9";
}

.icon-uniF2AA:before {
    content: "\f2aa";
}

.icon-uniF2AB:before {
    content: "\f2ab";
}

.icon-uniF2AC:before {
    content: "\f2ac";
}

.icon-uniF2AD:before {
    content: "\f2ad";
}

.icon-uniF2AE:before {
    content: "\f2ae";
}

.icon-fast-backward:before {
    content: "\f2af";
}

.icon-uniF2B0:before {
    content: "\f2b0";
}

.icon-uniF2B1:before {
    content: "\f2b1";
}

.icon-uniF2B2:before {
    content: "\f2b2";
}

.icon-uniF2B3:before {
    content: "\f2b3";
}

.icon-uniF2B4:before {
    content: "\f2b4";
}

.icon-uniF2B5:before {
    content: "\f2b5";
}

.icon-uniF2B6:before {
    content: "\f2b6";
}

.icon-uniF2B7:before {
    content: "\f2b7";
}

.icon-uniF2B8:before {
    content: "\f2b8";
}

.icon-uniF2B9:before {
    content: "\f2b9";
}

.icon-uniF2BA:before {
    content: "\f2ba";
}

.icon-uniF2BB:before {
    content: "\f2bb";
}

.icon-uniF2BC:before {
    content: "\f2bc";
}

.icon-uniF2BD:before {
    content: "\f2bd";
}

.icon-uniF2BE:before {
    content: "\f2be";
}

.icon-backward1:before {
    content: "\f2bf";
}

.icon-uniF2C0:before {
    content: "\f2c0";
}

.icon-uniF2C1:before {
    content: "\f2c1";
}

.icon-uniF2C2:before {
    content: "\f2c2";
}

.icon-uniF2C3:before {
    content: "\f2c3";
}

.icon-uniF2C4:before {
    content: "\f2c4";
}

.icon-uniF2C5:before {
    content: "\f2c5";
}

.icon-uniF2C6:before {
    content: "\f2c6";
}

.icon-uniF2C7:before {
    content: "\f2c7";
}

.icon-uniF2C8:before {
    content: "\f2c8";
}

.icon-uniF2C9:before {
    content: "\f2c9";
}

.icon-uniF2CA:before {
    content: "\f2ca";
}

.icon-uniF2CB:before {
    content: "\f2cb";
}

.icon-uniF2CC:before {
    content: "\f2cc";
}

.icon-uniF2CD:before {
    content: "\f2cd";
}

.icon-uniF2CE:before {
    content: "\f2ce";
}

.icon-play11:before {
    content: "\f2cf";
}

.icon-uniF2D0:before {
    content: "\f2d0";
}

.icon-uniF2D1:before {
    content: "\f2d1";
}

.icon-uniF2D2:before {
    content: "\f2d2";
}

.icon-uniF2D3:before {
    content: "\f2d3";
}

.icon-uniF2D4:before {
    content: "\f2d4";
}

.icon-uniF2D5:before {
    content: "\f2d5";
}

.icon-uniF2D6:before {
    content: "\f2d6";
}

.icon-uniF2D7:before {
    content: "\f2d7";
}

.icon-uniF2D8:before {
    content: "\f2d8";
}

.icon-uniF2D9:before {
    content: "\f2d9";
}

.icon-uniF2DA:before {
    content: "\f2da";
}

.icon-uniF2DB:before {
    content: "\f2db";
}

.icon-uniF2DC:before {
    content: "\f2dc";
}

.icon-uniF2DD:before {
    content: "\f2dd";
}

.icon-uniF2DE:before {
    content: "\f2de";
}

.icon-pause1:before {
    content: "\f2df";
}

.icon-stop1:before {
    content: "\f2e0";
}

.icon-forward1:before {
    content: "\f2e1";
}

.icon-fast-forward:before {
    content: "\f2e2";
}

.icon-step-forward:before {
    content: "\f2e3";
}

.icon-eject11:before {
    content: "\f2e4";
}

.icon-chevron-left:before {
    content: "\f2e5";
}

.icon-chevron-right:before {
    content: "\f2e6";
}

.icon-plus-circle:before {
    content: "\f2e7";
}

.icon-minus-circle:before {
    content: "\f2e8";
}

.icon-times-circle:before {
    content: "\f2e9";
}

.icon-check-circle:before {
    content: "\f2ea";
}

.icon-question-circle:before {
    content: "\f2eb";
}

.icon-info-circle:before {
    content: "\f2ec";
}

.icon-crosshairs:before {
    content: "\f2ed";
}

.icon-ban:before {
    content: "\f2ee";
}

.icon-arrow-left:before {
    content: "\f2ef";
}

.icon-arrow-right1:before {
    content: "\f2f0";
}

.icon-arrow-up1:before {
    content: "\f2f1";
}

.icon-arrow-down1:before {
    content: "\f2f2";
}

.icon-share11:before {
    content: "\f2f3";
}

.icon-expand:before {
    content: "\f2f4";
}

.icon-compress:before {
    content: "\f2f5";
}

.icon-plus1:before {
    content: "\f2f6";
}

.icon-minus1:before {
    content: "\f2f7";
}

.icon-asterisk1:before {
    content: "\f2f8";
}

.icon-exclamation-circle:before {
    content: "\f2f9";
}

.icon-gift1:before {
    content: "\f2fa";
}

.icon-leaf11:before {
    content: "\f2fb";
}

.icon-fire1:before {
    content: "\f2fc";
}

.icon-eye:before {
    content: "\f2fd";
}

.icon-eye-slash:before {
    content: "\f2fe";
}

.icon-exclamation-triangle:before {
    content: "\f2ff";
}

.icon-plane1:before {
    content: "\f300";
}

.icon-calendar-alt:before {
    content: "\f301";
}

.icon-random1:before {
    content: "\f302";
}

.icon-comment1:before {
    content: "\f303";
}

.icon-magnet1:before {
    content: "\f304";
}

.icon-chevron-up:before {
    content: "\f305";
}

.icon-chevron-down:before {
    content: "\f306";
}

.icon-retweet1:before {
    content: "\f307";
}

.icon-shopping-cart:before {
    content: "\f308";
}

.icon-folder:before {
    content: "\f309";
}

.icon-folder-open:before {
    content: "\f30a";
}

.icon-chart-bar:before {
    content: "\f30b";
}

.icon-camera-retro:before {
    content: "\f30c";
}

.icon-key1:before {
    content: "\f30d";
}

.icon-cogs11:before {
    content: "\f30e";
}

.icon-comments1:before {
    content: "\f30f";
}

.icon-star-half1:before {
    content: "\f310";
}

.icon-thumbtack:before {
    content: "\f311";
}

.icon-trophy1:before {
    content: "\f312";
}

.icon-upload1:before {
    content: "\f313";
}

.icon-lemon1:before {
    content: "\f314";
}

.icon-phone1:before {
    content: "\f315";
}

.icon-phone-square:before {
    content: "\f316";
}

.icon-unlock1:before {
    content: "\f317";
}

.icon-credit-card:before {
    content: "\f318";
}

.icon-rss1:before {
    content: "\f319";
}

.icon-hdd1:before {
    content: "\f31a";
}

.icon-bullhorn1:before {
    content: "\f31b";
}

.icon-certificate1:before {
    content: "\f31c";
}

.icon-hand-point-right:before {
    content: "\f31d";
}

.icon-hand-point-left:before {
    content: "\f31e";
}

.icon-hand-point-up:before {
    content: "\f31f";
}

.icon-hand-point-down:before {
    content: "\f320";
}

.icon-arrow-circle-left:before {
    content: "\f321";
}

.icon-arrow-circle-right:before {
    content: "\f322";
}

.icon-arrow-circle-up:before {
    content: "\f323";
}

.icon-arrow-circle-down:before {
    content: "\f324";
}

.icon-globe1:before {
    content: "\f325";
}

.icon-wrench11:before {
    content: "\f326";
}

.icon-tasks1:before {
    content: "\f327";
}

.icon-filter11:before {
    content: "\f328";
}

.icon-briefcase1:before {
    content: "\f329";
}

.icon-arrows-alt:before {
    content: "\f32a";
}

.icon-users:before {
    content: "\f32b";
}

.icon-link11:before {
    content: "\f32c";
}

.icon-cloud1:before {
    content: "\f32d";
}

.icon-flask:before {
    content: "\f32e";
}

.icon-cut1:before {
    content: "\f32f";
}

.icon-copy1:before {
    content: "\f330";
}

.icon-paperclip:before {
    content: "\f331";
}

.icon-save1:before {
    content: "\f332";
}

.icon-square:before {
    content: "\f333";
}

.icon-bars:before {
    content: "\f334";
}

.icon-list-ul:before {
    content: "\f335";
}

.icon-list-ol:before {
    content: "\f336";
}

.icon-strikethrough1:before {
    content: "\f337";
}

.icon-underline1:before {
    content: "\f338";
}

.icon-table1:before {
    content: "\f339";
}

.icon-magic1:before {
    content: "\f33a";
}

.icon-truck1:before {
    content: "\f33b";
}

.icon-money-bill:before {
    content: "\f33c";
}

.icon-caret-down:before {
    content: "\f33d";
}

.icon-caret-up:before {
    content: "\f33e";
}

.icon-caret-left:before {
    content: "\f33f";
}

.icon-caret-right:before {
    content: "\f340";
}

.icon-columns1:before {
    content: "\f341";
}

.icon-sort1:before {
    content: "\f342";
}

.icon-sort-down:before {
    content: "\f343";
}

.icon-sort-up:before {
    content: "\f344";
}

.icon-envelope1:before {
    content: "\f345";
}

.icon-undo1:before {
    content: "\f346";
}

.icon-gavel:before {
    content: "\f347";
}

.icon-bolt1:before {
    content: "\f348";
}

.icon-sitemap1:before {
    content: "\f349";
}

.icon-umbrella1:before {
    content: "\f34a";
}

.icon-paste1:before {
    content: "\f34b";
}

.icon-lightbulb:before {
    content: "\f34c";
}

.icon-user-md:before {
    content: "\f34d";
}

.icon-stethoscope1:before {
    content: "\f34e";
}

.icon-suitcase1:before {
    content: "\f34f";
}

.icon-bell1:before {
    content: "\f350";
}

.icon-coffee1:before {
    content: "\f351";
}

.icon-hospital1:before {
    content: "\f352";
}

.icon-ambulance1:before {
    content: "\f353";
}

.icon-medkit1:before {
    content: "\f354";
}

.icon-fighter-jet:before {
    content: "\f355";
}

.icon-beer1:before {
    content: "\f356";
}

.icon-h-square:before {
    content: "\f357";
}

.icon-plus-square:before {
    content: "\f358";
}

.icon-angle-double-left:before {
    content: "\f359";
}

.icon-angle-double-right:before {
    content: "\f35a";
}

.icon-angle-double-up:before {
    content: "\f35b";
}

.icon-angle-double-down:before {
    content: "\f35c";
}

.icon-angle-left:before {
    content: "\f35d";
}

.icon-angle-right:before {
    content: "\f35e";
}

.icon-angle-up:before {
    content: "\f35f";
}

.icon-angle-down:before {
    content: "\f360";
}

.icon-desktop1:before {
    content: "\f361";
}

.icon-laptop1:before {
    content: "\f362";
}

.icon-tablet11:before {
    content: "\f363";
}

.icon-mobile:before {
    content: "\f364";
}

.icon-quote-left:before {
    content: "\f365";
}

.icon-quote-right:before {
    content: "\f366";
}

.icon-spinner1:before {
    content: "\f367";
}

.icon-circle1:before {
    content: "\f368";
}

.icon-smile1:before {
    content: "\f369";
}

.icon-frown1:before {
    content: "\f36a";
}

.icon-meh1:before {
    content: "\f36b";
}

.icon-gamepad1:before {
    content: "\f36c";
}

.icon-keyboard1:before {
    content: "\f36d";
}

.icon-flag-checkered:before {
    content: "\f36e";
}

.icon-terminal1:before {
    content: "\f36f";
}

.icon-code1:before {
    content: "\f370";
}

.icon-reply-all:before {
    content: "\f371";
}

.icon-location-arrow:before {
    content: "\f372";
}

.icon-crop11:before {
    content: "\f373";
}

.icon-code-branch:before {
    content: "\f374";
}

.icon-unlink1:before {
    content: "\f375";
}

.icon-question1:before {
    content: "\f376";
}

.icon-info:before {
    content: "\f377";
}

.icon-exclamation1:before {
    content: "\f378";
}

.icon-superscript11:before {
    content: "\f379";
}

.icon-subscript11:before {
    content: "\f37a";
}

.icon-eraser:before {
    content: "\f37b";
}

.icon-puzzle-piece:before {
    content: "\f37c";
}

.icon-microphone1:before {
    content: "\f37d";
}

.icon-microphone-slash:before {
    content: "\f37e";
}

.icon-calendar1:before {
    content: "\f37f";
}

.icon-fire-extinguisher:before {
    content: "\f380";
}

.icon-rocket11:before {
    content: "\f381";
}

.icon-chevron-circle-left:before {
    content: "\f382";
}

.icon-chevron-circle-right:before {
    content: "\f383";
}

.icon-chevron-circle-up:before {
    content: "\f384";
}

.icon-chevron-circle-down:before {
    content: "\f385";
}

.icon-anchor1:before {
    content: "\f386";
}

.icon-unlock-alt:before {
    content: "\f387";
}

.icon-bullseye1:before {
    content: "\f388";
}

.icon-ellipsis-h:before {
    content: "\f389";
}

.icon-ellipsis-v:before {
    content: "\f38a";
}

.icon-rss-square:before {
    content: "\f38b";
}

.icon-play-circle:before {
    content: "\f38c";
}

.icon-minus-square:before {
    content: "\f38d";
}

.icon-check-square:before {
    content: "\f38e";
}

.icon-pen-square:before {
    content: "\f38f";
}

.icon-share-square:before {
    content: "\f390";
}

.icon-compass11:before {
    content: "\f391";
}

.icon-caret-square-down:before {
    content: "\f392";
}

.icon-caret-square-up:before {
    content: "\f393";
}

.icon-caret-square-right:before {
    content: "\f394";
}

.icon-euro-sign:before {
    content: "\f395";
}

.icon-pound-sign:before {
    content: "\f396";
}

.icon-dollar-sign:before {
    content: "\f397";
}

.icon-rupee-sign:before {
    content: "\f398";
}

.icon-yen-sign:before {
    content: "\f399";
}

.icon-ruble-sign:before {
    content: "\f39a";
}

.icon-won-sign:before {
    content: "\f39b";
}

.icon-file1:before {
    content: "\f39c";
}

.icon-file-alt:before {
    content: "\f39d";
}

.icon-sort-alpha-down:before {
    content: "\f39e";
}

.icon-sort-alpha-up:before {
    content: "\f39f";
}

.icon-sort-amount-down:before {
    content: "\f3a0";
}

.icon-sort-amount-up:before {
    content: "\f3a1";
}

.icon-sort-numeric-down:before {
    content: "\f3a2";
}

.icon-sort-numeric-up:before {
    content: "\f3a3";
}

.icon-thumbs-up:before {
    content: "\f3a4";
}

.icon-thumbs-down:before {
    content: "\f3a5";
}

.icon-female1:before {
    content: "\f3a6";
}

.icon-male1:before {
    content: "\f3a7";
}

.icon-sun1:before {
    content: "\f3a8";
}

.icon-moon:before {
    content: "\f3a9";
}

.icon-archive1:before {
    content: "\f3aa";
}

.icon-bug1:before {
    content: "\f3ab";
}

.icon-caret-square-left:before {
    content: "\f3ac";
}

.icon-dot-circle:before {
    content: "\f3ad";
}

.icon-wheelchair:before {
    content: "\f3ae";
}

.icon-lira-sign:before {
    content: "\f3af";
}

.icon-space-shuttle:before {
    content: "\f3b0";
}

.icon-envelope-square:before {
    content: "\f3b1";
}

.icon-university:before {
    content: "\f3b2";
}

.icon-graduation-cap:before {
    content: "\f3b3";
}

.icon-language:before {
    content: "\f3b4";
}

.icon-fax:before {
    content: "\f3b5";
}

.icon-building1:before {
    content: "\f3b6";
}

.icon-child:before {
    content: "\f3b7";
}

.icon-paw:before {
    content: "\f3b8";
}

.icon-cube:before {
    content: "\f3b9";
}

.icon-cubes:before {
    content: "\f3ba";
}

.icon-recycle:before {
    content: "\f3bb";
}

.icon-car:before {
    content: "\f3bc";
}

.icon-taxi:before {
    content: "\f3bd";
}

.icon-tree:before {
    content: "\f3be";
}

.icon-database:before {
    content: "\f3bf";
}

.icon-file-pdf:before {
    content: "\f3c0";
}

.icon-file-word:before {
    content: "\f3c1";
}

.icon-file-excel:before {
    content: "\f3c2";
}

.icon-file-powerpoint:before {
    content: "\f3c3";
}

.icon-file-image:before {
    content: "\f3c4";
}

.icon-file-archive:before {
    content: "\f3c5";
}

.icon-file-audio:before {
    content: "\f3c6";
}

.icon-file-video:before {
    content: "\f3c7";
}

.icon-file-code:before {
    content: "\f3c8";
}

.icon-life-ring:before {
    content: "\f3c9";
}

.icon-circle-notch:before {
    content: "\f3ca";
}

.icon-paper-plane:before {
    content: "\f3cb";
}

.icon-history1:before {
    content: "\f3cc";
}

.icon-heading:before {
    content: "\f3cd";
}

.icon-paragraph:before {
    content: "\f3ce";
}

.icon-sliders-h:before {
    content: "\f3cf";
}

.icon-share-alt:before {
    content: "\f3d0";
}

.icon-share-alt-square:before {
    content: "\f3d1";
}

.icon-bomb:before {
    content: "\f3d2";
}

.icon-futbol:before {
    content: "\f3d3";
}

.icon-tty:before {
    content: "\f3d4";
}

.icon-binoculars:before {
    content: "\f3d5";
}

.icon-plug:before {
    content: "\f3d6";
}

.icon-newspaper1:before {
    content: "\f3d7";
}

.icon-wifi:before {
    content: "\f3d8";
}

.icon-calculator:before {
    content: "\f3d9";
}

.icon-bell-slash:before {
    content: "\f3da";
}

.icon-trash1:before {
    content: "\f3db";
}

.icon-copyright:before {
    content: "\f3dc";
}

.icon-at:before {
    content: "\f3dd";
}

.icon-eye-dropper:before {
    content: "\f3de";
}

.icon-paint-brush:before {
    content: "\f3df";
}

.icon-birthday-cake:before {
    content: "\f3e0";
}

.icon-chart-area:before {
    content: "\f3e1";
}

.icon-chart-pie:before {
    content: "\f3e2";
}

.icon-chart-line:before {
    content: "\f3e3";
}

.icon-toggle-off:before {
    content: "\f3e4";
}

.icon-toggle-on:before {
    content: "\f3e5";
}

.icon-bicycle:before {
    content: "\f3e6";
}

.icon-bus:before {
    content: "\f3e7";
}

.icon-closed-captioning:before {
    content: "\f3e8";
}

.icon-shekel-sign:before {
    content: "\f3e9";
}

.icon-cart-plus:before {
    content: "\f3ea";
}

.icon-cart-arrow-down:before {
    content: "\f3eb";
}

.icon-ship:before {
    content: "\f3ec";
}

.icon-user-secret:before {
    content: "\f3ed";
}

.icon-motorcycle:before {
    content: "\f3ee";
}

.icon-street-view:before {
    content: "\f3ef";
}

.icon-heartbeat:before {
    content: "\f3f0";
}

.icon-venus1:before {
    content: "\f3f1";
}

.icon-mars:before {
    content: "\f3f2";
}

.icon-mercury:before {
    content: "\f3f3";
}

.icon-transgender:before {
    content: "\f3f4";
}

.icon-transgender-alt:before {
    content: "\f3f5";
}

.icon-venus-double:before {
    content: "\f3f6";
}

.icon-mars-double:before {
    content: "\f3f7";
}

.icon-venus-mars:before {
    content: "\f3f8";
}

.icon-mars-stroke:before {
    content: "\f3f9";
}

.icon-mars-stroke-v:before {
    content: "\f3fa";
}

.icon-mars-stroke-h:before {
    content: "\f3fb";
}

.icon-neuter:before {
    content: "\f3fc";
}

.icon-genderless:before {
    content: "\f3fd";
}

.icon-server:before {
    content: "\f3fe";
}

.icon-user-plus:before {
    content: "\f3ff";
}

.icon-user-times:before {
    content: "\f400";
}

.icon-bed:before {
    content: "\f401";
}

.icon-train:before {
    content: "\f402";
}

.icon-subway:before {
    content: "\f403";
}

.icon-battery-full:before {
    content: "\f404";
}

.icon-battery-three-quarters:before {
    content: "\f405";
}

.icon-battery-half:before {
    content: "\f406";
}

.icon-battery-quarter:before {
    content: "\f407";
}

.icon-battery-empty:before {
    content: "\f408";
}

.icon-mouse-pointer:before {
    content: "\f409";
}

.icon-i-cursor:before {
    content: "\f40a";
}

.icon-object-group:before {
    content: "\f40b";
}

.icon-object-ungroup:before {
    content: "\f40c";
}

.icon-sticky-note:before {
    content: "\f40d";
}

.icon-clone:before {
    content: "\f40e";
}

.icon-balance-scale:before {
    content: "\f40f";
}

.icon-hourglass-start:before {
    content: "\f410";
}

.icon-hourglass-half:before {
    content: "\f411";
}

.icon-hourglass-end:before {
    content: "\f412";
}

.icon-hourglass:before {
    content: "\f413";
}

.icon-hand-rock:before {
    content: "\f414";
}

.icon-hand-paper:before {
    content: "\f415";
}

.icon-hand-scissors:before {
    content: "\f416";
}

.icon-hand-lizard:before {
    content: "\f417";
}

.icon-hand-spock:before {
    content: "\f418";
}

.icon-hand-pointer:before {
    content: "\f419";
}

.icon-hand-peace:before {
    content: "\f41a";
}

.icon-trademark:before {
    content: "\f41b";
}

.icon-registered:before {
    content: "\f41c";
}

.icon-tv1:before {
    content: "\f41d";
}

.icon-calendar-plus:before {
    content: "\f41e";
}

.icon-calendar-minus:before {
    content: "\f41f";
}

.icon-calendar-times:before {
    content: "\f420";
}

.icon-calendar-check:before {
    content: "\f421";
}

.icon-industry:before {
    content: "\f422";
}

.icon-map-pin:before {
    content: "\f423";
}

.icon-map-signs:before {
    content: "\f424";
}

.icon-map1:before {
    content: "\f425";
}

.icon-comment-alt:before {
    content: "\f426";
}

.icon-pause-circle:before {
    content: "\f427";
}

.icon-stop-circle:before {
    content: "\f428";
}

.icon-shopping-bag:before {
    content: "\f429";
}

.icon-shopping-basket:before {
    content: "\f42a";
}

.icon-hashtag:before {
    content: "\f42b";
}

.icon-percent:before {
    content: "\f42c";
}

.icon-universal-access:before {
    content: "\f42d";
}

.icon-blind:before {
    content: "\f42e";
}

.icon-audio-description:before {
    content: "\f42f";
}

.icon-phone-volume:before {
    content: "\f430";
}

.icon-braille:before {
    content: "\f431";
}

.icon-assistive-listening-systems:before {
    content: "\f432";
}

.icon-american-sign-language-interpreting:before {
    content: "\f433";
}

.icon-deaf:before {
    content: "\f434";
}

.icon-sign-language:before {
    content: "\f435";
}

.icon-low-vision:before {
    content: "\f436";
}

.icon-handshake:before {
    content: "\f437";
}

.icon-envelope-open:before {
    content: "\f438";
}

.icon-address-book:before {
    content: "\f439";
}

.icon-address-card:before {
    content: "\f43a";
}

.icon-user-circle:before {
    content: "\f43b";
}

.icon-id-badge:before {
    content: "\f43c";
}

.icon-id-card:before {
    content: "\f43d";
}

.icon-thermometer-full:before {
    content: "\f43e";
}

.icon-thermometer-three-quarters:before {
    content: "\f43f";
}

.icon-thermometer-half:before {
    content: "\f440";
}

.icon-thermometer-quarter:before {
    content: "\f441";
}

.icon-thermometer-empty:before {
    content: "\f442";
}

.icon-shower:before {
    content: "\f443";
}

.icon-bath:before {
    content: "\f444";
}

.icon-podcast:before {
    content: "\f445";
}

.icon-window-maximize:before {
    content: "\f446";
}

.icon-window-minimize:before {
    content: "\f447";
}

.icon-window-restore:before {
    content: "\f448";
}

.icon-microchip:before {
    content: "\f449";
}

.icon-snowflake:before {
    content: "\f44a";
}

.icon-utensil-spoon:before {
    content: "\f44b";
}

.icon-utensils:before {
    content: "\f44c";
}

.icon-undo-alt:before {
    content: "\f44d";
}

.icon-trash-alt:before {
    content: "\f44e";
}

.icon-sync-alt:before {
    content: "\f44f";
}

.icon-stopwatch:before {
    content: "\f450";
}

.icon-sign-out-alt:before {
    content: "\f451";
}

.icon-sign-in-alt:before {
    content: "\f452";
}

.icon-redo-alt:before {
    content: "\f453";
}

.icon-poo:before {
    content: "\f454";
}

.icon-images:before {
    content: "\f455";
}

.icon-pencil-alt:before {
    content: "\f456";
}

.icon-pen:before {
    content: "\f457";
}

.icon-pen-alt:before {
    content: "\f458";
}

.icon-long-arrow-alt-down:before {
    content: "\f459";
}

.icon-long-arrow-alt-left:before {
    content: "\f45a";
}

.icon-long-arrow-alt-right:before {
    content: "\f45b";
}

.icon-long-arrow-alt-up:before {
    content: "\f45c";
}

.icon-expand-arrows-alt:before {
    content: "\f45d";
}

.icon-clipboard1:before {
    content: "\f45e";
}

.icon-arrows-alt-h:before {
    content: "\f45f";
}

.icon-arrows-alt-v:before {
    content: "\f460";
}

.icon-arrow-alt-circle-down:before {
    content: "\f461";
}

.icon-arrow-alt-circle-left:before {
    content: "\f462";
}

.icon-arrow-alt-circle-right:before {
    content: "\f463";
}

.icon-arrow-alt-circle-up:before {
    content: "\f464";
}

.icon-external-link-alt:before {
    content: "\f465";
}

.icon-external-link-square-alt:before {
    content: "\f466";
}

.icon-exchange-alt:before {
    content: "\f467";
}

.icon-cloud-download-alt:before {
    content: "\f468";
}

.icon-cloud-upload-alt:before {
    content: "\f469";
}

.icon-gem:before {
    content: "\f46a";
}

.icon-level-down-alt:before {
    content: "\f46b";
}

.icon-level-up-alt:before {
    content: "\f46c";
}

.icon-lock-open:before {
    content: "\f46d";
}

.icon-map-marker-alt:before {
    content: "\f46e";
}

.icon-microphone-alt:before {
    content: "\f46f";
}

.icon-mobile-alt:before {
    content: "\f470";
}

.icon-money-bill-alt:before {
    content: "\f471";
}

.icon-phone-slash:before {
    content: "\f472";
}

.icon-portrait:before {
    content: "\f473";
}

.icon-reply1:before {
    content: "\f474";
}

.icon-shield-alt:before {
    content: "\f475";
}

.icon-tablet-alt:before {
    content: "\f476";
}

.icon-tachometer-alt:before {
    content: "\f477";
}

.icon-ticket-alt:before {
    content: "\f478";
}

.icon-user-alt:before {
    content: "\f479";
}

.icon-window-close:before {
    content: "\f47a";
}

.icon-baseball-ball:before {
    content: "\f47b";
}

.icon-basketball-ball:before {
    content: "\f47c";
}

.icon-bowling-ball:before {
    content: "\f47d";
}

.icon-chess:before {
    content: "\f47e";
}

.icon-chess-bishop:before {
    content: "\f47f";
}

.icon-chess-board:before {
    content: "\f480";
}

.icon-chess-king:before {
    content: "\f481";
}

.icon-chess-knight:before {
    content: "\f482";
}

.icon-chess-pawn:before {
    content: "\f483";
}

.icon-chess-queen:before {
    content: "\f484";
}

.icon-chess-rook:before {
    content: "\f485";
}

.icon-dumbbell:before {
    content: "\f486";
}

.icon-football-ball:before {
    content: "\f487";
}

.icon-golf-ball:before {
    content: "\f488";
}

.icon-hockey-puck:before {
    content: "\f489";
}

.icon-quidditch:before {
    content: "\f48a";
}

.icon-square-full:before {
    content: "\f48b";
}

.icon-table-tennis:before {
    content: "\f48c";
}

.icon-volleyball-ball:before {
    content: "\f48d";
}

.icon-allergies:before {
    content: "\f48e";
}

.icon-band-aid:before {
    content: "\f48f";
}

.icon-box:before {
    content: "\f490";
}

.icon-boxes:before {
    content: "\f491";
}

.icon-briefcase-medical:before {
    content: "\f492";
}

.icon-burn:before {
    content: "\f493";
}

.icon-capsules:before {
    content: "\f494";
}

.icon-clipboard-check:before {
    content: "\f495";
}

.icon-clipboard-list:before {
    content: "\f496";
}

.icon-diagnoses:before {
    content: "\f497";
}

.icon-dna:before {
    content: "\f498";
}

.icon-dolly:before {
    content: "\f499";
}

.icon-dolly-flatbed:before {
    content: "\f49a";
}

.icon-file-medical:before {
    content: "\f49b";
}

.icon-file-medical-alt:before {
    content: "\f49c";
}

.icon-first-aid:before {
    content: "\f49d";
}

.icon-hospital-alt:before {
    content: "\f49e";
}

.icon-hospital-symbol:before {
    content: "\f49f";
}

.icon-id-card-alt:before {
    content: "\f4a0";
}

.icon-notes-medical:before {
    content: "\f4a1";
}

.icon-pallet:before {
    content: "\f4a2";
}

.icon-pills:before {
    content: "\f4a3";
}

.icon-prescription-bottle:before {
    content: "\f4a4";
}

.icon-prescription-bottle-alt:before {
    content: "\f4a5";
}

.icon-procedures:before {
    content: "\f4a6";
}

.icon-shipping-fast:before {
    content: "\f4a7";
}

.icon-smoking:before {
    content: "\f4a8";
}

.icon-syringe:before {
    content: "\f4a9";
}

.icon-tablets:before {
    content: "\f4aa";
}

.icon-thermometer:before {
    content: "\f4ab";
}

.icon-vial:before {
    content: "\f4ac";
}

.icon-vials:before {
    content: "\f4ad";
}

.icon-warehouse:before {
    content: "\f4ae";
}

.icon-weight:before {
    content: "\f4af";
}

.icon-x-ray:before {
    content: "\f4b0";
}

.icon-box-open:before {
    content: "\f4b1";
}

.icon-comment-dots:before {
    content: "\f4b2";
}

.icon-comment-slash:before {
    content: "\f4b3";
}

.icon-ion-alert-circled:before {
    content: "\f4b4";
}

.icon-ion-alert:before {
    content: "\f4b5";
}

.icon-ion-android-add-circle:before {
    content: "\f4b6";
}

.icon-ion-android-add:before {
    content: "\f4b7";
}

.icon-couch:before {
    content: "\f4b8";
}

.icon-donate:before {
    content: "\f4b9";
}

.icon-dove:before {
    content: "\f4ba";
}

.icon-ion-android-alarm-clock:before {
    content: "\f4bb";
}

.icon-ion-android-alert:before {
    content: "\f4bc";
}

.icon-hand-holding:before {
    content: "\f4bd";
}

.icon-hand-holding-heart:before {
    content: "\f4be";
}

.icon-ion-android-apps:before {
    content: "\f4bf";
}

.icon-hand-holding-usd:before {
    content: "\f4c0";
}

.icon-ion-android-archive:before {
    content: "\f4c1";
}

.icon-hands:before {
    content: "\f4c2";
}

.icon-ion-android-arrow-back:before {
    content: "\f4c3";
}

.icon-hands-helping:before {
    content: "\f4c4";
}

.icon-ion-android-arrow-down:before {
    content: "\f4c5";
}

.icon-ion-android-arrow-dropdown-circle:before {
    content: "\f4c6";
}

.icon-ion-android-arrow-dropdown:before {
    content: "\f4c7";
}

.icon-ion-android-arrow-dropleft-circle:before {
    content: "\f4c8";
}

.icon-ion-android-arrow-dropleft:before {
    content: "\f4c9";
}

.icon-ion-android-arrow-dropright-circle:before {
    content: "\f4ca";
}

.icon-ion-android-arrow-dropright:before {
    content: "\f4cb";
}

.icon-ion-android-arrow-dropup-circle:before {
    content: "\f4cc";
}

.icon-parachute-box:before {
    content: "\f4cd";
}

.icon-people-carry:before {
    content: "\f4ce";
}

.icon-ion-android-arrow-dropup:before {
    content: "\f4cf";
}

.icon-ion-android-arrow-forward:before {
    content: "\f4d0";
}

.icon-ion-android-arrow-up:before {
    content: "\f4d1";
}

.icon-ion-android-attach:before {
    content: "\f4d2";
}

.icon-piggy-bank:before {
    content: "\f4d3";
}

.icon-ion-android-bar:before {
    content: "\f4d4";
}

.icon-ion-android-bicycle:before {
    content: "\f4d5";
}

.icon-ribbon:before {
    content: "\f4d6";
}

.icon-route:before {
    content: "\f4d7";
}

.icon-seedling:before {
    content: "\f4d8";
}

.icon-sign:before {
    content: "\f4d9";
}

.icon-smile-wink:before {
    content: "\f4da";
}

.icon-tape:before {
    content: "\f4db";
}

.icon-ion-android-boat:before {
    content: "\f4dc";
}

.icon-ion-android-bookmark:before {
    content: "\f4dd";
}

.icon-truck-loading:before {
    content: "\f4de";
}

.icon-truck-moving:before {
    content: "\f4df";
}

.icon-ion-android-bulb:before {
    content: "\f4e0";
}

.icon-ion-android-bus:before {
    content: "\f4e1";
}

.icon-video-slash:before {
    content: "\f4e2";
}

.icon-wine-glass:before {
    content: "\f4e3";
}

.icon-ion-android-calendar:before {
    content: "\f4e4";
}

.icon-ion-android-call:before {
    content: "\f4e5";
}

.icon-ion-android-camera:before {
    content: "\f4e6";
}

.icon-ion-android-cancel:before {
    content: "\f4e7";
}

.icon-ion-android-car:before {
    content: "\f4e8";
}

.icon-ion-android-cart:before {
    content: "\f4e9";
}

.icon-ion-android-chat:before {
    content: "\f4ea";
}

.icon-ion-android-checkbox-blank:before {
    content: "\f4eb";
}

.icon-ion-android-checkbox-outline-blank:before {
    content: "\f4ec";
}

.icon-ion-android-checkbox-outline:before {
    content: "\f4ed";
}

.icon-ion-android-checkbox:before {
    content: "\f4ee";
}

.icon-ion-android-checkmark-circle:before {
    content: "\f4ef";
}

.icon-ion-android-clipboard:before {
    content: "\f4f0";
}

.icon-ion-android-close:before {
    content: "\f4f1";
}

.icon-ion-android-cloud-circle:before {
    content: "\f4f2";
}

.icon-ion-android-cloud-done:before {
    content: "\f4f3";
}

.icon-ion-android-cloud-outline:before {
    content: "\f4f4";
}

.icon-ion-android-cloud:before {
    content: "\f4f5";
}

.icon-ion-android-color-palette:before {
    content: "\f4f6";
}

.icon-ion-android-compass:before {
    content: "\f4f7";
}

.icon-ion-android-contact:before {
    content: "\f4f8";
}

.icon-ion-android-contacts:before {
    content: "\f4f9";
}

.icon-user-alt-slash:before {
    content: "\f4fa";
}

.icon-user-astronaut:before {
    content: "\f4fb";
}

.icon-user-check:before {
    content: "\f4fc";
}

.icon-user-clock:before {
    content: "\f4fd";
}

.icon-user-cog:before {
    content: "\f4fe";
}

.icon-user-edit:before {
    content: "\f4ff";
}

.icon-user-friends:before {
    content: "\f500";
}

.icon-user-graduate:before {
    content: "\f501";
}

.icon-user-lock:before {
    content: "\f502";
}

.icon-user-minus:before {
    content: "\f503";
}

.icon-user-ninja:before {
    content: "\f504";
}

.icon-user-shield:before {
    content: "\f505";
}

.icon-user-slash:before {
    content: "\f506";
}

.icon-user-tag:before {
    content: "\f507";
}

.icon-user-tie:before {
    content: "\f508";
}

.icon-users-cog:before {
    content: "\f509";
}

.icon-ion-android-contract:before {
    content: "\f50a";
}

.icon-ion-android-create:before {
    content: "\f50b";
}

.icon-ion-android-delete:before {
    content: "\f50c";
}

.icon-ion-android-desktop:before {
    content: "\f50d";
}

.icon-ion-android-document:before {
    content: "\f50e";
}

.icon-ion-android-done-all:before {
    content: "\f50f";
}

.icon-ion-android-done:before {
    content: "\f510";
}

.icon-ion-android-drafts:before {
    content: "\f511";
}

.icon-ion-android-exit:before {
    content: "\f512";
}

.icon-ion-android-expand:before {
    content: "\f513";
}

.icon-ion-android-favorite-outline:before {
    content: "\f514";
}

.icon-balance-scale-left:before {
    content: "\f515";
}

.icon-balance-scale-right:before {
    content: "\f516";
}

.icon-blender:before {
    content: "\f517";
}

.icon-book-open:before {
    content: "\f518";
}

.icon-broadcast-tower:before {
    content: "\f519";
}

.icon-broom:before {
    content: "\f51a";
}

.icon-chalkboard:before {
    content: "\f51b";
}

.icon-chalkboard-teacher:before {
    content: "\f51c";
}

.icon-church:before {
    content: "\f51d";
}

.icon-coins:before {
    content: "\f51e";
}

.icon-compact-disc:before {
    content: "\f51f";
}

.icon-crow:before {
    content: "\f520";
}

.icon-crown:before {
    content: "\f521";
}

.icon-dice1:before {
    content: "\f522";
}

.icon-dice-five:before {
    content: "\f523";
}

.icon-dice-four:before {
    content: "\f524";
}

.icon-dice-one:before {
    content: "\f525";
}

.icon-dice-six:before {
    content: "\f526";
}

.icon-dice-three:before {
    content: "\f527";
}

.icon-dice-two:before {
    content: "\f528";
}

.icon-divide:before {
    content: "\f529";
}

.icon-door-closed:before {
    content: "\f52a";
}

.icon-door-open:before {
    content: "\f52b";
}

.icon-equals:before {
    content: "\f52c";
}

.icon-feather:before {
    content: "\f52d";
}

.icon-frog:before {
    content: "\f52e";
}

.icon-gas-pump:before {
    content: "\f52f";
}

.icon-glasses:before {
    content: "\f530";
}

.icon-greater-than:before {
    content: "\f531";
}

.icon-greater-than-equal:before {
    content: "\f532";
}

.icon-helicopter:before {
    content: "\f533";
}

.icon-infinity:before {
    content: "\f534";
}

.icon-kiwi-bird:before {
    content: "\f535";
}

.icon-less-than:before {
    content: "\f536";
}

.icon-less-than-equal:before {
    content: "\f537";
}

.icon-memory:before {
    content: "\f538";
}

.icon-microphone-alt-slash:before {
    content: "\f539";
}

.icon-money-bill-wave:before {
    content: "\f53a";
}

.icon-money-bill-wave-alt:before {
    content: "\f53b";
}

.icon-money-check:before {
    content: "\f53c";
}

.icon-money-check-alt:before {
    content: "\f53d";
}

.icon-not-equal:before {
    content: "\f53e";
}

.icon-palette:before {
    content: "\f53f";
}

.icon-parking:before {
    content: "\f540";
}

.icon-percentage:before {
    content: "\f541";
}

.icon-project-diagram:before {
    content: "\f542";
}

.icon-receipt:before {
    content: "\f543";
}

.icon-robot:before {
    content: "\f544";
}

.icon-ruler:before {
    content: "\f545";
}

.icon-ruler-combined:before {
    content: "\f546";
}

.icon-ruler-horizontal:before {
    content: "\f547";
}

.icon-ruler-vertical:before {
    content: "\f548";
}

.icon-school:before {
    content: "\f549";
}

.icon-screwdriver:before {
    content: "\f54a";
}

.icon-shoe-prints:before {
    content: "\f54b";
}

.icon-skull:before {
    content: "\f54c";
}

.icon-smoking-ban:before {
    content: "\f54d";
}

.icon-store:before {
    content: "\f54e";
}

.icon-store-alt:before {
    content: "\f54f";
}

.icon-stream:before {
    content: "\f550";
}

.icon-stroopwafel:before {
    content: "\f551";
}

.icon-toolbox:before {
    content: "\f552";
}

.icon-tshirt:before {
    content: "\f553";
}

.icon-walking:before {
    content: "\f554";
}

.icon-wallet:before {
    content: "\f555";
}

.icon-angry1:before {
    content: "\f556";
}

.icon-archway:before {
    content: "\f557";
}

.icon-atlas:before {
    content: "\f558";
}

.icon-award:before {
    content: "\f559";
}

.icon-backspace:before {
    content: "\f55a";
}

.icon-bezier-curve:before {
    content: "\f55b";
}

.icon-bong:before {
    content: "\f55c";
}

.icon-brush:before {
    content: "\f55d";
}

.icon-bus-alt:before {
    content: "\f55e";
}

.icon-cannabis:before {
    content: "\f55f";
}

.icon-check-double:before {
    content: "\f560";
}

.icon-cocktail:before {
    content: "\f561";
}

.icon-concierge-bell:before {
    content: "\f562";
}

.icon-cookie:before {
    content: "\f563";
}

.icon-cookie-bite:before {
    content: "\f564";
}

.icon-crop-alt:before {
    content: "\f565";
}

.icon-digital-tachograph:before {
    content: "\f566";
}

.icon-dizzy:before {
    content: "\f567";
}

.icon-drafting-compass:before {
    content: "\f568";
}

.icon-drum:before {
    content: "\f569";
}

.icon-drum-steelpan:before {
    content: "\f56a";
}

.icon-feather-alt:before {
    content: "\f56b";
}

.icon-file-contract:before {
    content: "\f56c";
}

.icon-file-download:before {
    content: "\f56d";
}

.icon-file-export:before {
    content: "\f56e";
}

.icon-file-import:before {
    content: "\f56f";
}

.icon-file-invoice:before {
    content: "\f570";
}

.icon-file-invoice-dollar:before {
    content: "\f571";
}

.icon-file-prescription:before {
    content: "\f572";
}

.icon-file-signature:before {
    content: "\f573";
}

.icon-file-upload:before {
    content: "\f574";
}

.icon-fill:before {
    content: "\f575";
}

.icon-fill-drip:before {
    content: "\f576";
}

.icon-fingerprint:before {
    content: "\f577";
}

.icon-fish:before {
    content: "\f578";
}

.icon-flushed:before {
    content: "\f579";
}

.icon-frown-open:before {
    content: "\f57a";
}

.icon-glass-martini-alt:before {
    content: "\f57b";
}

.icon-globe-africa:before {
    content: "\f57c";
}

.icon-globe-americas:before {
    content: "\f57d";
}

.icon-globe-asia:before {
    content: "\f57e";
}

.icon-grimace:before {
    content: "\f57f";
}

.icon-grin:before {
    content: "\f580";
}

.icon-grin-alt:before {
    content: "\f581";
}

.icon-grin-beam:before {
    content: "\f582";
}

.icon-grin-beam-sweat:before {
    content: "\f583";
}

.icon-grin-hearts:before {
    content: "\f584";
}

.icon-grin-squint:before {
    content: "\f585";
}

.icon-grin-squint-tears:before {
    content: "\f586";
}

.icon-grin-stars:before {
    content: "\f587";
}

.icon-grin-tears:before {
    content: "\f588";
}

.icon-grin-tongue:before {
    content: "\f589";
}

.icon-grin-tongue-squint:before {
    content: "\f58a";
}

.icon-grin-tongue-wink:before {
    content: "\f58b";
}

.icon-grin-wink:before {
    content: "\f58c";
}

.icon-grip-horizontal:before {
    content: "\f58d";
}

.icon-grip-vertical:before {
    content: "\f58e";
}

.icon-headphones-alt:before {
    content: "\f58f";
}

.icon-headset:before {
    content: "\f590";
}

.icon-highlighter:before {
    content: "\f591";
}

.icon-ion-android-favorite:before {
    content: "\f592";
}

.icon-hot-tub:before {
    content: "\f593";
}

.icon-hotel:before {
    content: "\f594";
}

.icon-joint:before {
    content: "\f595";
}

.icon-kiss:before {
    content: "\f596";
}

.icon-kiss-beam:before {
    content: "\f597";
}

.icon-kiss-wink-heart:before {
    content: "\f598";
}

.icon-laugh:before {
    content: "\f599";
}

.icon-laugh-beam:before {
    content: "\f59a";
}

.icon-laugh-squint:before {
    content: "\f59b";
}

.icon-laugh-wink:before {
    content: "\f59c";
}

.icon-luggage-cart:before {
    content: "\f59d";
}

.icon-ion-android-film:before {
    content: "\f59e";
}

.icon-map-marked:before {
    content: "\f59f";
}

.icon-map-marked-alt:before {
    content: "\f5a0";
}

.icon-marker:before {
    content: "\f5a1";
}

.icon-medal:before {
    content: "\f5a2";
}

.icon-ion-android-folder-open:before {
    content: "\f5a3";
}

.icon-meh-blank:before {
    content: "\f5a4";
}

.icon-meh-rolling-eyes:before {
    content: "\f5a5";
}

.icon-monument:before {
    content: "\f5a6";
}

.icon-mortar-pestle:before {
    content: "\f5a7";
}

.icon-ion-android-folder:before {
    content: "\f5a8";
}

.icon-ion-android-funnel:before {
    content: "\f5a9";
}

.icon-paint-roller:before {
    content: "\f5aa";
}

.icon-passport:before {
    content: "\f5ab";
}

.icon-pen-fancy:before {
    content: "\f5ac";
}

.icon-pen-nib:before {
    content: "\f5ad";
}

.icon-pencil-ruler:before {
    content: "\f5ae";
}

.icon-plane-arrival:before {
    content: "\f5af";
}

.icon-plane-departure:before {
    content: "\f5b0";
}

.icon-prescription:before {
    content: "\f5b1";
}

.icon-ion-android-globe:before {
    content: "\f5b2";
}

.icon-sad-cry:before {
    content: "\f5b3";
}

.icon-sad-tear:before {
    content: "\f5b4";
}

.icon-ion-android-hand:before {
    content: "\f5b5";
}

.icon-shuttle-van:before {
    content: "\f5b6";
}

.icon-signature:before {
    content: "\f5b7";
}

.icon-smile-beam:before {
    content: "\f5b8";
}

.icon-ion-android-hangout:before {
    content: "\f5b9";
}

.icon-solar-panel:before {
    content: "\f5ba";
}

.icon-spa:before {
    content: "\f5bb";
}

.icon-splotch:before {
    content: "\f5bc";
}

.icon-spray-can:before {
    content: "\f5bd";
}

.icon-ion-android-happy:before {
    content: "\f5be";
}

.icon-stamp:before {
    content: "\f5bf";
}

.icon-star-half-alt:before {
    content: "\f5c0";
}

.icon-suitcase-rolling:before {
    content: "\f5c1";
}

.icon-surprise:before {
    content: "\f5c2";
}

.icon-swatchbook:before {
    content: "\f5c3";
}

.icon-swimmer:before {
    content: "\f5c4";
}

.icon-swimming-pool:before {
    content: "\f5c5";
}

.icon-ion-android-home:before {
    content: "\f5c6";
}

.icon-tint-slash:before {
    content: "\f5c7";
}

.icon-tired:before {
    content: "\f5c8";
}

.icon-tooth:before {
    content: "\f5c9";
}

.icon-umbrella-beach:before {
    content: "\f5ca";
}

.icon-vector-square:before {
    content: "\f5cb";
}

.icon-ion-android-image:before {
    content: "\f5cc";
}

.icon-weight-hanging:before {
    content: "\f5cd";
}

.icon-wine-glass-alt:before {
    content: "\f5ce";
}

.icon-ion-android-laptop:before {
    content: "\f5cf";
}

.icon-air-freshener:before {
    content: "\f5d0";
}

.icon-apple-alt:before {
    content: "\f5d1";
}

.icon-atom:before {
    content: "\f5d2";
}

.icon-ion-android-list:before {
    content: "\f5d3";
}

.icon-ion-android-locate:before {
    content: "\f5d4";
}

.icon-ion-android-lock:before {
    content: "\f5d5";
}

.icon-ion-android-mail:before {
    content: "\f5d6";
}

.icon-bone:before {
    content: "\f5d7";
}

.icon-ion-android-map:before {
    content: "\f5d8";
}

.icon-ion-android-menu:before {
    content: "\f5d9";
}

.icon-book-reader:before {
    content: "\f5da";
}

.icon-ion-android-microphone-off:before {
    content: "\f5db";
}

.icon-brain:before {
    content: "\f5dc";
}

.icon-ion-android-microphone:before {
    content: "\f5dd";
}

.icon-car-alt:before {
    content: "\f5de";
}

.icon-car-battery:before {
    content: "\f5df";
}

.icon-ion-android-more-horizontal:before {
    content: "\f5e0";
}

.icon-car-crash:before {
    content: "\f5e1";
}

.icon-ion-android-more-vertical:before {
    content: "\f5e2";
}

.icon-ion-android-navigate:before {
    content: "\f5e3";
}

.icon-car-side:before {
    content: "\f5e4";
}

.icon-ion-android-notifications-none:before {
    content: "\f5e5";
}

.icon-ion-android-notifications-off:before {
    content: "\f5e6";
}

.icon-charging-station:before {
    content: "\f5e7";
}

.icon-ion-android-notifications:before {
    content: "\f5e8";
}

.icon-ion-android-open:before {
    content: "\f5e9";
}

.icon-ion-android-options:before {
    content: "\f5ea";
}

.icon-directions:before {
    content: "\f5eb";
}

.icon-ion-android-people:before {
    content: "\f5ec";
}

.icon-ion-android-person-add:before {
    content: "\f5ed";
}

.icon-draw-polygon:before {
    content: "\f5ee";
}

.icon-ion-android-person:before {
    content: "\f5ef";
}

.icon-ion-android-phone-landscape:before {
    content: "\f5f0";
}

.icon-ion-android-phone-portrait:before {
    content: "\f5f1";
}

.icon-ion-android-pin:before {
    content: "\f5f2";
}

.icon-ion-android-plane:before {
    content: "\f5f3";
}

.icon-ion-android-playstore:before {
    content: "\f5f4";
}

.icon-ion-android-print:before {
    content: "\f5f5";
}

.icon-ion-android-radio-button-off:before {
    content: "\f5f6";
}

.icon-ion-android-radio-button-on:before {
    content: "\f5f7";
}

.icon-ion-android-refresh:before {
    content: "\f5f8";
}

.icon-ion-android-remove-circle:before {
    content: "\f5f9";
}

.icon-ion-android-remove:before {
    content: "\f5fa";
}

.icon-ion-android-restaurant:before {
    content: "\f5fb";
}

.icon-laptop-code:before {
    content: "\f5fc";
}

.icon-layer-group:before {
    content: "\f5fd";
}

.icon-ion-android-sad:before {
    content: "\f5fe";
}

.icon-ion-android-search:before {
    content: "\f5ff";
}

.icon-ion-android-send:before {
    content: "\f600";
}

.icon-ion-android-settings:before {
    content: "\f601";
}

.icon-ion-android-share-alt:before {
    content: "\f602";
}

.icon-ion-android-share:before {
    content: "\f603";
}

.icon-ion-android-star-half:before {
    content: "\f604";
}

.icon-ion-android-star-outline:before {
    content: "\f605";
}

.icon-ion-android-star:before {
    content: "\f606";
}

.icon-ion-android-stopwatch:before {
    content: "\f607";
}

.icon-ion-android-subway:before {
    content: "\f608";
}

.icon-ion-android-sunny:before {
    content: "\f609";
}

.icon-ion-android-sync:before {
    content: "\f60a";
}

.icon-ion-android-textsms:before {
    content: "\f60b";
}

.icon-ion-android-time:before {
    content: "\f60c";
}

.icon-ion-android-train:before {
    content: "\f60d";
}

.icon-ion-android-unlock:before {
    content: "\f60e";
}

.icon-ion-android-upload:before {
    content: "\f60f";
}

.icon-microscope:before {
    content: "\f610";
}

.icon-ion-android-volume-down:before {
    content: "\f611";
}

.icon-ion-android-volume-mute:before {
    content: "\f612";
}

.icon-oil-can:before {
    content: "\f613";
}

.icon-ion-android-volume-off:before {
    content: "\f614";
}

.icon-ion-android-volume-up:before {
    content: "\f615";
}

.icon-ion-android-walk:before {
    content: "\f616";
}

.icon-ion-android-warning:before {
    content: "\f617";
}

.icon-ion-android-watch:before {
    content: "\f618";
}

.icon-poop:before {
    content: "\f619";
}

.icon-ion-android-wifi:before {
    content: "\f61a";
}

.icon-ion-aperture:before {
    content: "\f61b";
}

.icon-ion-archive:before {
    content: "\f61c";
}

.icon-ion-arrow-down-a:before {
    content: "\f61d";
}

.icon-ion-arrow-down-b:before {
    content: "\f61e";
}

.icon-shapes:before {
    content: "\f61f";
}

.icon-ion-arrow-down-c:before {
    content: "\f620";
}

.icon-star-of-life:before {
    content: "\f621";
}

.icon-ion-arrow-expand:before {
    content: "\f622";
}

.icon-ion-arrow-graph-down-left:before {
    content: "\f623";
}

.icon-ion-arrow-graph-down-right:before {
    content: "\f624";
}

.icon-ion-arrow-graph-up-left:before {
    content: "\f625";
}

.icon-ion-arrow-graph-up-right:before {
    content: "\f626";
}

.icon-ion-arrow-left-a:before {
    content: "\f627";
}

.icon-ion-arrow-left-b:before {
    content: "\f628";
}

.icon-ion-arrow-left-c:before {
    content: "\f629";
}

.icon-ion-arrow-move:before {
    content: "\f62a";
}

.icon-ion-arrow-resize:before {
    content: "\f62b";
}

.icon-ion-arrow-return-left:before {
    content: "\f62c";
}

.icon-ion-arrow-return-right:before {
    content: "\f62d";
}

.icon-teeth:before {
    content: "\f62e";
}

.icon-teeth-open:before {
    content: "\f62f";
}

.icon-theater-masks:before {
    content: "\f630";
}

.icon-ion-arrow-right-a:before {
    content: "\f631";
}

.icon-ion-arrow-right-b:before {
    content: "\f632";
}

.icon-ion-arrow-right-c:before {
    content: "\f633";
}

.icon-ion-arrow-shrink:before {
    content: "\f634";
}

.icon-ion-arrow-swap:before {
    content: "\f635";
}

.icon-ion-arrow-up-a:before {
    content: "\f636";
}

.icon-traffic-light:before {
    content: "\f637";
}

.icon-ion-arrow-up-b:before {
    content: "\f638";
}

.icon-ion-arrow-up-c:before {
    content: "\f639";
}

.icon-ion-asterisk:before {
    content: "\f63a";
}

.icon-truck-monster:before {
    content: "\f63b";
}

.icon-truck-pickup:before {
    content: "\f63c";
}

.icon-ion-at:before {
    content: "\f63d";
}

.icon-ion-backspace-outline:before {
    content: "\f63e";
}

.icon-ion-backspace:before {
    content: "\f63f";
}

.icon-ion-bag:before {
    content: "\f640";
}

.icon-ad:before {
    content: "\f641";
}

.icon-ion-battery-charging:before {
    content: "\f642";
}

.icon-ion-battery-empty:before {
    content: "\f643";
}

.icon-ankh:before {
    content: "\f644";
}

.icon-ion-battery-full:before {
    content: "\f645";
}

.icon-ion-battery-half:before {
    content: "\f646";
}

.icon-bible:before {
    content: "\f647";
}

.icon-ion-battery-low:before {
    content: "\f648";
}

.icon-ion-beaker:before {
    content: "\f649";
}

.icon-business-time:before {
    content: "\f64a";
}

.icon-ion-beer:before {
    content: "\f64b";
}

.icon-ion-bluetooth:before {
    content: "\f64c";
}

.icon-ion-bonfire:before {
    content: "\f64d";
}

.icon-ion-bookmark:before {
    content: "\f64e";
}

.icon-city:before {
    content: "\f64f";
}

.icon-ion-bowtie:before {
    content: "\f650";
}

.icon-comment-dollar:before {
    content: "\f651";
}

.icon-ion-briefcase:before {
    content: "\f652";
}

.icon-comments-dollar:before {
    content: "\f653";
}

.icon-cross1:before {
    content: "\f654";
}

.icon-dharmachakra:before {
    content: "\f655";
}

.icon-ion-bug:before {
    content: "\f656";
}

.icon-ion-calculator:before {
    content: "\f657";
}

.icon-envelope-open-text:before {
    content: "\f658";
}

.icon-ion-calendar:before {
    content: "\f659";
}

.icon-ion-camera:before {
    content: "\f65a";
}

.icon-ion-card:before {
    content: "\f65b";
}

.icon-ion-cash:before {
    content: "\f65c";
}

.icon-folder-minus:before {
    content: "\f65d";
}

.icon-folder-plus:before {
    content: "\f65e";
}

.icon-ion-chatbox-working:before {
    content: "\f65f";
}

.icon-ion-chatbox:before {
    content: "\f660";
}

.icon-ion-chatboxes:before {
    content: "\f661";
}

.icon-funnel-dollar:before {
    content: "\f662";
}

.icon-ion-chatbubble-working:before {
    content: "\f663";
}

.icon-gopuram:before {
    content: "\f664";
}

.icon-hamsa:before {
    content: "\f665";
}

.icon-haykal:before {
    content: "\f666";
}

.icon-ion-chatbubble:before {
    content: "\f667";
}

.icon-ion-chatbubbles:before {
    content: "\f668";
}

.icon-jedi:before {
    content: "\f669";
}

.icon-journal-whills:before {
    content: "\f66a";
}

.icon-kaaba:before {
    content: "\f66b";
}

.icon-ion-checkmark-circled:before {
    content: "\f66c";
}

.icon-khanda:before {
    content: "\f66d";
}

.icon-ion-checkmark-round:before {
    content: "\f66e";
}

.icon-landmark:before {
    content: "\f66f";
}

.icon-ion-checkmark:before {
    content: "\f670";
}

.icon-ion-chevron-down:before {
    content: "\f671";
}

.icon-ion-chevron-left:before {
    content: "\f672";
}

.icon-ion-chevron-right:before {
    content: "\f673";
}

.icon-mail-bulk:before {
    content: "\f674";
}

.icon-ion-chevron-up:before {
    content: "\f675";
}

.icon-menorah:before {
    content: "\f676";
}

.icon-ion-clipboard:before {
    content: "\f677";
}

.icon-mosque:before {
    content: "\f678";
}

.icon-om:before {
    content: "\f679";
}

.icon-ion-clock:before {
    content: "\f67a";
}

.icon-pastafarianism:before {
    content: "\f67b";
}

.icon-peace:before {
    content: "\f67c";
}

.icon-ion-close-circled:before {
    content: "\f67d";
}

.icon-ion-close-round:before {
    content: "\f67e";
}

.icon-place-of-worship:before {
    content: "\f67f";
}

.icon-ion-close:before {
    content: "\f680";
}

.icon-poll:before {
    content: "\f681";
}

.icon-poll-h:before {
    content: "\f682";
}

.icon-pray:before {
    content: "\f683";
}

.icon-praying-hands:before {
    content: "\f684";
}

.icon-ion-closed-captioning:before {
    content: "\f685";
}

.icon-ion-cloud:before {
    content: "\f686";
}

.icon-quran:before {
    content: "\f687";
}

.icon-search-dollar:before {
    content: "\f688";
}

.icon-search-location:before {
    content: "\f689";
}

.icon-ion-code-download:before {
    content: "\f68a";
}

.icon-ion-code-working:before {
    content: "\f68b";
}

.icon-ion-code:before {
    content: "\f68c";
}

.icon-ion-coffee:before {
    content: "\f68d";
}

.icon-ion-compass:before {
    content: "\f68e";
}

.icon-ion-compose:before {
    content: "\f68f";
}

.icon-ion-connection-bars:before {
    content: "\f690";
}

.icon-ion-contrast:before {
    content: "\f691";
}

.icon-ion-crop:before {
    content: "\f692";
}

.icon-ion-cube:before {
    content: "\f693";
}

.icon-ion-disc:before {
    content: "\f694";
}

.icon-ion-document-text:before {
    content: "\f695";
}

.icon-socks:before {
    content: "\f696";
}

.icon-ion-document:before {
    content: "\f697";
}

.icon-square-root-alt:before {
    content: "\f698";
}

.icon-star-and-crescent:before {
    content: "\f699";
}

.icon-star-of-david:before {
    content: "\f69a";
}

.icon-synagogue:before {
    content: "\f69b";
}

.icon-ion-drag:before {
    content: "\f69c";
}

.icon-ion-earth:before {
    content: "\f69d";
}

.icon-ion-easel:before {
    content: "\f69e";
}

.icon-ion-edit:before {
    content: "\f69f";
}

.icon-torah:before {
    content: "\f6a0";
}

.icon-torii-gate:before {
    content: "\f6a1";
}

.icon-ion-egg:before {
    content: "\f6a2";
}

.icon-ion-eject:before {
    content: "\f6a3";
}

.icon-ion-email-unread:before {
    content: "\f6a4";
}

.icon-ion-email:before {
    content: "\f6a5";
}

.icon-ion-erlenmeyer-flask-bubbles:before {
    content: "\f6a6";
}

.icon-vihara:before {
    content: "\f6a7";
}

.icon-ion-erlenmeyer-flask:before {
    content: "\f6a8";
}

.icon-volume-mute:before {
    content: "\f6a9";
}

.icon-ion-eye-disabled:before {
    content: "\f6aa";
}

.icon-ion-eye:before {
    content: "\f6ab";
}

.icon-ion-female:before {
    content: "\f6ac";
}

.icon-yin-yang:before {
    content: "\f6ad";
}

.icon-ion-filing:before {
    content: "\f6ae";
}

.icon-ion-film-marker:before {
    content: "\f6af";
}

.icon-ion-fireball:before {
    content: "\f6b0";
}

.icon-ion-flag:before {
    content: "\f6b1";
}

.icon-ion-flame:before {
    content: "\f6b2";
}

.icon-ion-flash-off:before {
    content: "\f6b3";
}

.icon-ion-flash:before {
    content: "\f6b4";
}

.icon-ion-folder:before {
    content: "\f6b5";
}

.icon-blender-phone:before {
    content: "\f6b6";
}

.icon-book-dead:before {
    content: "\f6b7";
}

.icon-ion-fork-repo:before {
    content: "\f6b8";
}

.icon-ion-fork:before {
    content: "\f6b9";
}

.icon-ion-forward:before {
    content: "\f6ba";
}

.icon-campground:before {
    content: "\f6bb";
}

.icon-ion-funnel:before {
    content: "\f6bc";
}

.icon-ion-gear-a:before {
    content: "\f6bd";
}

.icon-cat:before {
    content: "\f6be";
}

.icon-ion-gear-b:before {
    content: "\f6bf";
}

.icon-chair:before {
    content: "\f6c0";
}

.icon-ion-grid:before {
    content: "\f6c1";
}

.icon-ion-hammer:before {
    content: "\f6c2";
}

.icon-cloud-moon:before {
    content: "\f6c3";
}

.icon-cloud-sun:before {
    content: "\f6c4";
}

.icon-ion-happy-outline:before {
    content: "\f6c5";
}

.icon-ion-happy:before {
    content: "\f6c6";
}

.icon-ion-headphone:before {
    content: "\f6c7";
}

.icon-ion-heart-broken:before {
    content: "\f6c8";
}

.icon-ion-heart:before {
    content: "\f6c9";
}

.icon-ion-help-buoy:before {
    content: "\f6ca";
}

.icon-ion-help-circled:before {
    content: "\f6cb";
}

.icon-ion-help:before {
    content: "\f6cc";
}

.icon-ion-home:before {
    content: "\f6cd";
}

.icon-ion-icecream:before {
    content: "\f6ce";
}

.icon-dice-d20:before {
    content: "\f6cf";
}

.icon-ion-image:before {
    content: "\f6d0";
}

.icon-dice-d6:before {
    content: "\f6d1";
}

.icon-ion-images:before {
    content: "\f6d2";
}

.icon-dog:before {
    content: "\f6d3";
}

.icon-ion-information-circled:before {
    content: "\f6d4";
}

.icon-dragon:before {
    content: "\f6d5";
}

.icon-ion-information:before {
    content: "\f6d6";
}

.icon-drumstick-bite:before {
    content: "\f6d7";
}

.icon-ion-ionic:before {
    content: "\f6d8";
}

.icon-dungeon:before {
    content: "\f6d9";
}

.icon-ion-ios-alarm-outline:before {
    content: "\f6da";
}

.icon-ion-ios-alarm:before {
    content: "\f6db";
}

.icon-ion-ios-albums-outline:before {
    content: "\f6dc";
}

.icon-file-csv:before {
    content: "\f6dd";
}

.icon-fist-raised:before {
    content: "\f6de";
}

.icon-ion-ios-albums:before {
    content: "\f6df";
}

.icon-ion-ios-americanfootball-outline:before {
    content: "\f6e0";
}

.icon-ion-ios-americanfootball:before {
    content: "\f6e1";
}

.icon-ghost:before {
    content: "\f6e2";
}

.icon-hammer:before {
    content: "\f6e3";
}

.icon-ion-ios-analytics-outline:before {
    content: "\f6e4";
}

.icon-ion-ios-analytics:before {
    content: "\f6e5";
}

.icon-hanukiah:before {
    content: "\f6e6";
}

.icon-ion-ios-arrow-back:before {
    content: "\f6e7";
}

.icon-hat-wizard:before {
    content: "\f6e8";
}

.icon-ion-ios-arrow-down:before {
    content: "\f6e9";
}

.icon-ion-ios-arrow-forward:before {
    content: "\f6ea";
}

.icon-ion-ios-arrow-left:before {
    content: "\f6eb";
}

.icon-hiking:before {
    content: "\f6ec";
}

.icon-hippo:before {
    content: "\f6ed";
}

.icon-ion-ios-arrow-right:before {
    content: "\f6ee";
}

.icon-ion-ios-arrow-thin-down:before {
    content: "\f6ef";
}

.icon-horse:before {
    content: "\f6f0";
}

.icon-house-damage:before {
    content: "\f6f1";
}

.icon-hryvnia:before {
    content: "\f6f2";
}

.icon-ion-ios-arrow-thin-left:before {
    content: "\f6f3";
}

.icon-ion-ios-arrow-thin-right:before {
    content: "\f6f4";
}

.icon-ion-ios-arrow-thin-up:before {
    content: "\f6f5";
}

.icon-ion-ios-arrow-up:before {
    content: "\f6f6";
}

.icon-ion-ios-at-outline:before {
    content: "\f6f7";
}

.icon-ion-ios-at:before {
    content: "\f6f8";
}

.icon-ion-ios-barcode-outline:before {
    content: "\f6f9";
}

.icon-mask:before {
    content: "\f6fa";
}

.icon-ion-ios-barcode:before {
    content: "\f6fb";
}

.icon-mountain:before {
    content: "\f6fc";
}

.icon-ion-ios-baseball-outline:before {
    content: "\f6fd";
}

.icon-ion-ios-baseball:before {
    content: "\f6fe";
}

.icon-network-wired:before {
    content: "\f6ff";
}

.icon-otter:before {
    content: "\f700";
}

.icon-ion-ios-basketball-outline:before {
    content: "\f701";
}

.icon-ion-ios-basketball:before {
    content: "\f702";
}

.icon-ion-ios-bell-outline:before {
    content: "\f703";
}

.icon-ion-ios-bell:before {
    content: "\f704";
}

.icon-ion-ios-body-outline:before {
    content: "\f705";
}

.icon-ion-ios-body:before {
    content: "\f706";
}

.icon-ion-ios-bolt-outline:before {
    content: "\f707";
}

.icon-ion-ios-bolt:before {
    content: "\f708";
}

.icon-ion-ios-book-outline:before {
    content: "\f709";
}

.icon-ion-ios-book:before {
    content: "\f70a";
}

.icon-ring:before {
    content: "\f70b";
}

.icon-running:before {
    content: "\f70c";
}

.icon-ion-ios-bookmarks-outline:before {
    content: "\f70d";
}

.icon-scroll:before {
    content: "\f70e";
}

.icon-ion-ios-bookmarks:before {
    content: "\f70f";
}

.icon-ion-ios-box-outline:before {
    content: "\f710";
}

.icon-ion-ios-box:before {
    content: "\f711";
}

.icon-ion-ios-briefcase-outline:before {
    content: "\f712";
}

.icon-ion-ios-briefcase:before {
    content: "\f713";
}

.icon-skull-crossbones:before {
    content: "\f714";
}

.icon-slash:before {
    content: "\f715";
}

.icon-ion-ios-browsers-outline:before {
    content: "\f716";
}

.icon-spider:before {
    content: "\f717";
}

.icon-ion-ios-browsers:before {
    content: "\f718";
}

.icon-ion-ios-calculator-outline:before {
    content: "\f719";
}

.icon-ion-ios-calculator:before {
    content: "\f71a";
}

.icon-ion-ios-calendar-outline:before {
    content: "\f71b";
}

.icon-ion-ios-calendar:before {
    content: "\f71c";
}

.icon-ion-ios-camera-outline:before {
    content: "\f71d";
}

.icon-toilet-paper:before {
    content: "\f71e";
}

.icon-ion-ios-camera:before {
    content: "\f71f";
}

.icon-ion-ios-cart-outline:before {
    content: "\f720";
}

.icon-ion-ios-cart:before {
    content: "\f721";
}

.icon-tractor:before {
    content: "\f722";
}

.icon-ion-ios-chatboxes-outline:before {
    content: "\f723";
}

.icon-ion-ios-chatboxes:before {
    content: "\f724";
}

.icon-ion-ios-chatbubble-outline:before {
    content: "\f725";
}

.icon-ion-ios-chatbubble:before {
    content: "\f726";
}

.icon-ion-ios-checkmark-empty:before {
    content: "\f727";
}

.icon-user-injured:before {
    content: "\f728";
}

.icon-vr-cardboard:before {
    content: "\f729";
}

.icon-ion-ios-checkmark-outline:before {
    content: "\f72a";
}

.icon-ion-ios-checkmark:before {
    content: "\f72b";
}

.icon-ion-ios-circle-filled:before {
    content: "\f72c";
}

.icon-ion-ios-circle-outline:before {
    content: "\f72d";
}

.icon-wind:before {
    content: "\f72e";
}

.icon-wine-bottle:before {
    content: "\f72f";
}

.icon-ion-ios-clock-outline:before {
    content: "\f730";
}

.icon-ion-ios-clock:before {
    content: "\f731";
}

.icon-ion-ios-close-empty:before {
    content: "\f732";
}

.icon-ion-ios-close-outline:before {
    content: "\f733";
}

.icon-ion-ios-close:before {
    content: "\f734";
}

.icon-ion-ios-cloud-download-outline:before {
    content: "\f735";
}

.icon-ion-ios-cloud-download:before {
    content: "\f736";
}

.icon-ion-ios-cloud-outline:before {
    content: "\f737";
}

.icon-ion-ios-cloud-upload-outline:before {
    content: "\f738";
}

.icon-ion-ios-cloud-upload:before {
    content: "\f739";
}

.icon-ion-ios-cloud:before {
    content: "\f73a";
}

.icon-cloud-meatball:before {
    content: "\f73b";
}

.icon-cloud-moon-rain:before {
    content: "\f73c";
}

.icon-cloud-rain:before {
    content: "\f73d";
}

.icon-ion-ios-cloudy-night-outline:before {
    content: "\f73e";
}

.icon-ion-ios-cloudy-night:before {
    content: "\f73f";
}

.icon-cloud-showers-heavy:before {
    content: "\f740";
}

.icon-ion-ios-cloudy-outline:before {
    content: "\f741";
}

.icon-ion-ios-cloudy:before {
    content: "\f742";
}

.icon-cloud-sun-rain:before {
    content: "\f743";
}

.icon-ion-ios-cog-outline:before {
    content: "\f744";
}

.icon-ion-ios-cog:before {
    content: "\f745";
}

.icon-ion-ios-color-filter-outline:before {
    content: "\f746";
}

.icon-democrat:before {
    content: "\f747";
}

.icon-ion-ios-color-filter:before {
    content: "\f748";
}

.icon-ion-ios-color-wand-outline:before {
    content: "\f749";
}

.icon-ion-ios-color-wand:before {
    content: "\f74a";
}

.icon-ion-ios-compose-outline:before {
    content: "\f74b";
}

.icon-ion-ios-compose:before {
    content: "\f74c";
}

.icon-flag-usa:before {
    content: "\f74d";
}

.icon-ion-ios-contact-outline:before {
    content: "\f74e";
}

.icon-ion-ios-contact:before {
    content: "\f74f";
}

.icon-ion-ios-copy-outline:before {
    content: "\f750";
}

.icon-ion-ios-copy:before {
    content: "\f751";
}

.icon-ion-ios-crop-strong:before {
    content: "\f752";
}

.icon-meteor:before {
    content: "\f753";
}

.icon-ion-ios-crop:before {
    content: "\f754";
}

.icon-ion-ios-download-outline:before {
    content: "\f755";
}

.icon-person-booth:before {
    content: "\f756";
}

.icon-ion-ios-download:before {
    content: "\f757";
}

.icon-ion-ios-drag:before {
    content: "\f758";
}

.icon-ion-ios-email-outline:before {
    content: "\f759";
}

.icon-poo-storm:before {
    content: "\f75a";
}

.icon-rainbow:before {
    content: "\f75b";
}

.icon-ion-ios-email:before {
    content: "\f75c";
}

.icon-ion-ios-eye-outline:before {
    content: "\f75d";
}

.icon-republican:before {
    content: "\f75e";
}

.icon-smog:before {
    content: "\f75f";
}

.icon-ion-ios-eye:before {
    content: "\f760";
}

.icon-ion-ios-fastforward-outline:before {
    content: "\f761";
}

.icon-ion-ios-fastforward:before {
    content: "\f762";
}

.icon-ion-ios-filing-outline:before {
    content: "\f763";
}

.icon-ion-ios-filing:before {
    content: "\f764";
}

.icon-ion-ios-film-outline:before {
    content: "\f765";
}

.icon-ion-ios-film:before {
    content: "\f766";
}

.icon-ion-ios-flag-outline:before {
    content: "\f767";
}

.icon-ion-ios-flag:before {
    content: "\f768";
}

.icon-temperature-high:before {
    content: "\f769";
}

.icon-ion-ios-flame-outline:before {
    content: "\f76a";
}

.icon-temperature-low:before {
    content: "\f76b";
}

.icon-ion-ios-flame:before {
    content: "\f76c";
}

.icon-ion-ios-flask-outline:before {
    content: "\f76d";
}

.icon-ion-ios-flask:before {
    content: "\f76e";
}

.icon-ion-ios-flower-outline:before {
    content: "\f76f";
}

.icon-ion-ios-flower:before {
    content: "\f770";
}

.icon-ion-ios-folder-outline:before {
    content: "\f771";
}

.icon-vote-yea:before {
    content: "\f772";
}

.icon-water:before {
    content: "\f773";
}

.icon-ion-ios-folder:before {
    content: "\f774";
}

.icon-ion-ios-football-outline:before {
    content: "\f775";
}

.icon-ion-ios-football:before {
    content: "\f776";
}

.icon-ion-ios-game-controller-a-outline:before {
    content: "\f777";
}

.icon-ion-ios-game-controller-a:before {
    content: "\f778";
}

.icon-ion-ios-game-controller-b-outline:before {
    content: "\f779";
}

.icon-ion-ios-game-controller-b:before {
    content: "\f77a";
}

.icon-ion-ios-gear-outline:before {
    content: "\f77b";
}

.icon-baby:before {
    content: "\f77c";
}

.icon-baby-carriage:before {
    content: "\f77d";
}

.icon-ion-ios-gear:before {
    content: "\f77e";
}

.icon-ion-ios-glasses-outline:before {
    content: "\f77f";
}

.icon-biohazard:before {
    content: "\f780";
}

.icon-blog:before {
    content: "\f781";
}

.icon-ion-ios-glasses:before {
    content: "\f782";
}

.icon-calendar-day:before {
    content: "\f783";
}

.icon-calendar-week:before {
    content: "\f784";
}

.icon-ion-ios-grid-view-outline:before {
    content: "\f785";
}

.icon-candy-cane:before {
    content: "\f786";
}

.icon-carrot:before {
    content: "\f787";
}

.icon-cash-register:before {
    content: "\f788";
}

.icon-ion-ios-grid-view:before {
    content: "\f789";
}

.icon-ion-ios-heart-outline:before {
    content: "\f78a";
}

.icon-ion-ios-heart:before {
    content: "\f78b";
}

.icon-compress-arrows-alt:before {
    content: "\f78c";
}

.icon-ion-ios-help-empty:before {
    content: "\f78d";
}

.icon-ion-ios-help-outline:before {
    content: "\f78e";
}

.icon-ion-ios-help:before {
    content: "\f78f";
}

.icon-ion-ios-home-outline:before {
    content: "\f790";
}

.icon-ion-ios-home:before {
    content: "\f791";
}

.icon-ion-ios-infinite-outline:before {
    content: "\f792";
}

.icon-dumpster:before {
    content: "\f793";
}

.icon-dumpster-fire:before {
    content: "\f794";
}

.icon-ion-ios-infinite:before {
    content: "\f795";
}

.icon-ethernet:before {
    content: "\f796";
}

.icon-ion-ios-information-empty:before {
    content: "\f797";
}

.icon-ion-ios-information-outline:before {
    content: "\f798";
}

.icon-ion-ios-information:before {
    content: "\f799";
}

.icon-ion-ios-ionic-outline:before {
    content: "\f79a";
}

.icon-ion-ios-keypad-outline:before {
    content: "\f79b";
}

.icon-gifts:before {
    content: "\f79c";
}

.icon-ion-ios-keypad:before {
    content: "\f79d";
}

.icon-ion-ios-lightbulb-outline:before {
    content: "\f79e";
}

.icon-glass-cheers:before {
    content: "\f79f";
}

.icon-glass-whiskey:before {
    content: "\f7a0";
}

.icon-ion-ios-lightbulb:before {
    content: "\f7a1";
}

.icon-globe-europe:before {
    content: "\f7a2";
}

.icon-ion-ios-list-outline:before {
    content: "\f7a3";
}

.icon-grip-lines:before {
    content: "\f7a4";
}

.icon-grip-lines-vertical:before {
    content: "\f7a5";
}

.icon-guitar:before {
    content: "\f7a6";
}

.icon-ion-ios-list:before {
    content: "\f7a7";
}

.icon-ion-ios-location-outline:before {
    content: "\f7a8";
}

.icon-heart-broken1:before {
    content: "\f7a9";
}

.icon-holly-berry:before {
    content: "\f7aa";
}

.icon-horse-head:before {
    content: "\f7ab";
}

.icon-ion-ios-location:before {
    content: "\f7ac";
}

.icon-icicles:before {
    content: "\f7ad";
}

.icon-igloo:before {
    content: "\f7ae";
}

.icon-ion-ios-locked-outline:before {
    content: "\f7af";
}

.icon-ion-ios-locked:before {
    content: "\f7b0";
}

.icon-ion-ios-loop-strong:before {
    content: "\f7b1";
}

.icon-ion-ios-loop:before {
    content: "\f7b2";
}

.icon-ion-ios-medical-outline:before {
    content: "\f7b3";
}

.icon-ion-ios-medical:before {
    content: "\f7b4";
}

.icon-mitten:before {
    content: "\f7b5";
}

.icon-mug-hot:before {
    content: "\f7b6";
}

.icon-ion-ios-medkit-outline:before {
    content: "\f7b7";
}

.icon-ion-ios-medkit:before {
    content: "\f7b8";
}

.icon-radiation:before {
    content: "\f7b9";
}

.icon-radiation-alt:before {
    content: "\f7ba";
}

.icon-ion-ios-mic-off:before {
    content: "\f7bb";
}

.icon-ion-ios-mic-outline:before {
    content: "\f7bc";
}

.icon-restroom:before {
    content: "\f7bd";
}

.icon-ion-ios-mic:before {
    content: "\f7be";
}

.icon-satellite:before {
    content: "\f7bf";
}

.icon-satellite-dish:before {
    content: "\f7c0";
}

.icon-ion-ios-minus-empty:before {
    content: "\f7c1";
}

.icon-sd-card:before {
    content: "\f7c2";
}

.icon-ion-ios-minus-outline:before {
    content: "\f7c3";
}

.icon-sim-card:before {
    content: "\f7c4";
}

.icon-skating:before {
    content: "\f7c5";
}

.icon-ion-ios-minus:before {
    content: "\f7c6";
}

.icon-ion-ios-monitor-outline:before {
    content: "\f7c7";
}

.icon-ion-ios-monitor:before {
    content: "\f7c8";
}

.icon-skiing:before {
    content: "\f7c9";
}

.icon-skiing-nordic:before {
    content: "\f7ca";
}

.icon-ion-ios-moon-outline:before {
    content: "\f7cb";
}

.icon-sleigh:before {
    content: "\f7cc";
}

.icon-sms:before {
    content: "\f7cd";
}

.icon-snowboarding:before {
    content: "\f7ce";
}

.icon-ion-ios-moon:before {
    content: "\f7cf";
}

.icon-snowman:before {
    content: "\f7d0";
}

.icon-ion-ios-more-outline:before {
    content: "\f7d1";
}

.icon-snowplow:before {
    content: "\f7d2";
}

.icon-ion-ios-more:before {
    content: "\f7d3";
}

.icon-ion-ios-musical-note:before {
    content: "\f7d4";
}

.icon-ion-ios-musical-notes:before {
    content: "\f7d5";
}

.icon-ion-ios-navigate-outline:before {
    content: "\f7d6";
}

.icon-tenge:before {
    content: "\f7d7";
}

.icon-toilet:before {
    content: "\f7d8";
}

.icon-tools:before {
    content: "\f7d9";
}

.icon-tram:before {
    content: "\f7da";
}

.icon-ion-ios-navigate:before {
    content: "\f7db";
}

.icon-ion-ios-nutrition-outline:before {
    content: "\f7dc";
}

.icon-ion-ios-nutrition:before {
    content: "\f7dd";
}

.icon-ion-ios-paper-outline:before {
    content: "\f7de";
}

.icon-ion-ios-paper:before {
    content: "\f7df";
}

.icon-ion-ios-paperplane-outline:before {
    content: "\f7e0";
}

.icon-ion-ios-paperplane:before {
    content: "\f7e1";
}

.icon-ion-ios-partlysunny-outline:before {
    content: "\f7e2";
}

.icon-ion-ios-partlysunny:before {
    content: "\f7e3";
}

.icon-fire-alt:before {
    content: "\f7e4";
}

.icon-bacon:before {
    content: "\f7e5";
}

.icon-book-medical:before {
    content: "\f7e6";
}

.icon-ion-ios-pause-outline:before {
    content: "\f7e7";
}

.icon-ion-ios-pause:before {
    content: "\f7e8";
}

.icon-ion-ios-paw-outline:before {
    content: "\f7e9";
}

.icon-ion-ios-paw:before {
    content: "\f7ea";
}

.icon-ion-ios-people-outline:before {
    content: "\f7eb";
}

.icon-bread-slice:before {
    content: "\f7ec";
}

.icon-ion-ios-people:before {
    content: "\f7ed";
}

.icon-ion-ios-person-outline:before {
    content: "\f7ee";
}

.icon-cheese:before {
    content: "\f7ef";
}

.icon-ion-ios-person:before {
    content: "\f7f0";
}

.icon-ion-ios-personadd-outline:before {
    content: "\f7f1";
}

.icon-clinic-medical:before {
    content: "\f7f2";
}

.icon-ion-ios-personadd:before {
    content: "\f7f3";
}

.icon-ion-ios-photos-outline:before {
    content: "\f7f4";
}

.icon-comment-medical:before {
    content: "\f7f5";
}

.icon-ion-ios-photos:before {
    content: "\f7f6";
}

.icon-crutch:before {
    content: "\f7f7";
}

.icon-ion-ios-pie-outline:before {
    content: "\f7f8";
}

.icon-ion-ios-pie:before {
    content: "\f7f9";
}

.icon-ion-ios-pint-outline:before {
    content: "\f7fa";
}

.icon-egg:before {
    content: "\f7fb";
}

.icon-ion-ios-pint:before {
    content: "\f7fc";
}

.icon-ion-ios-play-outline:before {
    content: "\f7fd";
}

.icon-ion-ios-play:before {
    content: "\f7fe";
}

.icon-ion-ios-plus-empty:before {
    content: "\f7ff";
}

.icon-ion-ios-plus-outline:before {
    content: "\f800";
}

.icon-ion-ios-plus:before {
    content: "\f801";
}

.icon-ion-ios-pricetag-outline:before {
    content: "\f802";
}

.icon-ion-ios-pricetag:before {
    content: "\f803";
}

.icon-ion-ios-pricetags-outline:before {
    content: "\f804";
}

.icon-hamburger:before {
    content: "\f805";
}

.icon-hand-middle-finger:before {
    content: "\f806";
}

.icon-hard-hat:before {
    content: "\f807";
}

.icon-ion-ios-pricetags:before {
    content: "\f808";
}

.icon-ion-ios-printer-outline:before {
    content: "\f809";
}

.icon-ion-ios-printer:before {
    content: "\f80a";
}

.icon-ion-ios-pulse-strong:before {
    content: "\f80b";
}

.icon-ion-ios-pulse:before {
    content: "\f80c";
}

.icon-ion-ios-rainy-outline:before {
    content: "\f80d";
}

.icon-ion-ios-rainy:before {
    content: "\f80e";
}

.icon-hotdog:before {
    content: "\f80f";
}

.icon-ice-cream:before {
    content: "\f810";
}

.icon-ion-ios-recording-outline:before {
    content: "\f811";
}

.icon-laptop-medical:before {
    content: "\f812";
}

.icon-ion-ios-recording:before {
    content: "\f813";
}

.icon-ion-ios-redo-outline:before {
    content: "\f814";
}

.icon-pager:before {
    content: "\f815";
}

.icon-pepper-hot:before {
    content: "\f816";
}

.icon-ion-ios-redo:before {
    content: "\f817";
}

.icon-pizza-slice:before {
    content: "\f818";
}

.icon-ion-ios-refresh-empty:before {
    content: "\f819";
}

.icon-ion-ios-refresh-outline:before {
    content: "\f81a";
}

.icon-ion-ios-refresh:before {
    content: "\f81b";
}

.icon-ion-ios-reload:before {
    content: "\f81c";
}

.icon-ion-ios-reverse-camera-outline:before {
    content: "\f81d";
}

.icon-ion-ios-reverse-camera:before {
    content: "\f81e";
}

.icon-ion-ios-rewind-outline:before {
    content: "\f81f";
}

.icon-ion-ios-rewind:before {
    content: "\f820";
}

.icon-ion-ios-rose-outline:before {
    content: "\f821";
}

.icon-ion-ios-rose:before {
    content: "\f822";
}

.icon-ion-ios-search-strong:before {
    content: "\f823";
}

.icon-ion-ios-search:before {
    content: "\f824";
}

.icon-ion-ios-settings-strong:before {
    content: "\f825";
}

.icon-ion-ios-settings:before {
    content: "\f826";
}

.icon-ion-ios-shuffle-strong:before {
    content: "\f827";
}

.icon-ion-ios-shuffle:before {
    content: "\f828";
}

.icon-trash-restore:before {
    content: "\f829";
}

.icon-trash-restore-alt:before {
    content: "\f82a";
}

.icon-ion-ios-skipbackward-outline:before {
    content: "\f82b";
}

.icon-ion-ios-skipbackward:before {
    content: "\f82c";
}

.icon-ion-ios-skipforward-outline:before {
    content: "\f82d";
}

.icon-ion-ios-skipforward:before {
    content: "\f82e";
}

.icon-user-nurse:before {
    content: "\f82f";
}

.icon-ion-ios-snowy:before {
    content: "\f830";
}

.icon-ion-ios-speedometer-outline:before {
    content: "\f831";
}

.icon-ion-ios-speedometer:before {
    content: "\f832";
}

.icon-ion-ios-star-half:before {
    content: "\f833";
}

.icon-ion-ios-star-outline:before {
    content: "\f834";
}

.icon-ion-ios-star:before {
    content: "\f835";
}

.icon-ion-ios-stopwatch-outline:before {
    content: "\f836";
}

.icon-ion-ios-stopwatch:before {
    content: "\f837";
}

.icon-ion-ios-sunny-outline:before {
    content: "\f838";
}

.icon-ion-ios-sunny:before {
    content: "\f839";
}

.icon-ion-ios-telephone-outline:before {
    content: "\f83a";
}

.icon-ion-ios-telephone:before {
    content: "\f83b";
}

.icon-ion-ios-tennisball-outline:before {
    content: "\f83c";
}

.icon-ion-ios-tennisball:before {
    content: "\f83d";
}

.icon-wave-square:before {
    content: "\f83e";
}

.icon-ion-ios-thunderstorm-outline:before {
    content: "\f83f";
}

.icon-ion-ios-thunderstorm:before {
    content: "\f840";
}

.icon-ion-ios-time-outline:before {
    content: "\f841";
}

.icon-ion-ios-time:before {
    content: "\f842";
}

.icon-ion-ios-timer-outline:before {
    content: "\f843";
}

.icon-ion-ios-timer:before {
    content: "\f844";
}

.icon-ion-ios-toggle-outline:before {
    content: "\f845";
}

.icon-ion-ios-toggle:before {
    content: "\f846";
}

.icon-ion-ios-trash-outline:before {
    content: "\f847";
}

.icon-ion-ios-trash:before {
    content: "\f848";
}

.icon-ion-ios-undo-outline:before {
    content: "\f849";
}

.icon-biking:before {
    content: "\f84a";
}

.icon-ion-ios-undo:before {
    content: "\f84b";
}

.icon-border-all:before {
    content: "\f84c";
}

.icon-ion-ios-unlocked-outline:before {
    content: "\f84d";
}

.icon-ion-ios-unlocked:before {
    content: "\f84e";
}

.icon-ion-ios-upload-outline:before {
    content: "\f84f";
}

.icon-border-none:before {
    content: "\f850";
}

.icon-ion-ios-upload:before {
    content: "\f851";
}

.icon-ion-ios-videocam-outline:before {
    content: "\f852";
}

.icon-border-style:before {
    content: "\f853";
}

.icon-ion-ios-videocam:before {
    content: "\f854";
}

.icon-ion-ios-volume-high:before {
    content: "\f855";
}

.icon-ion-ios-volume-low:before {
    content: "\f856";
}

.icon-ion-ios-wineglass-outline:before {
    content: "\f857";
}

.icon-ion-ios-wineglass:before {
    content: "\f858";
}

.icon-ion-ios-world-outline:before {
    content: "\f859";
}

.icon-ion-ios-world:before {
    content: "\f85a";
}

.icon-ion-ipad:before {
    content: "\f85b";
}

.icon-ion-iphone:before {
    content: "\f85c";
}

.icon-ion-ipod:before {
    content: "\f85d";
}

.icon-ion-jet:before {
    content: "\f85e";
}

.icon-ion-key:before {
    content: "\f85f";
}

.icon-ion-knife:before {
    content: "\f860";
}

.icon-ion-laptop:before {
    content: "\f861";
}

.icon-ion-leaf:before {
    content: "\f862";
}

.icon-fan:before {
    content: "\f863";
}

.icon-ion-levels:before {
    content: "\f864";
}

.icon-ion-lightbulb:before {
    content: "\f865";
}

.icon-ion-link:before {
    content: "\f866";
}

.icon-ion-load-a:before {
    content: "\f867";
}

.icon-ion-load-b:before {
    content: "\f868";
}

.icon-ion-load-c:before {
    content: "\f869";
}

.icon-ion-load-d:before {
    content: "\f86a";
}

.icon-ion-location:before {
    content: "\f86b";
}

.icon-ion-lock-combination:before {
    content: "\f86c";
}

.icon-icons:before {
    content: "\f86d";
}

.icon-ion-locked:before {
    content: "\f86e";
}

.icon-ion-log-in:before {
    content: "\f86f";
}

.icon-ion-log-out:before {
    content: "\f870";
}

.icon-ion-loop:before {
    content: "\f871";
}

.icon-ion-magnet:before {
    content: "\f872";
}

.icon-ion-male:before {
    content: "\f873";
}

.icon-ion-man:before {
    content: "\f874";
}

.icon-ion-map:before {
    content: "\f875";
}

.icon-ion-medkit:before {
    content: "\f876";
}

.icon-ion-merge:before {
    content: "\f877";
}

.icon-ion-mic-a:before {
    content: "\f878";
}

.icon-phone-alt:before {
    content: "\f879";
}

.icon-ion-mic-b:before {
    content: "\f87a";
}

.icon-phone-square-alt:before {
    content: "\f87b";
}

.icon-photo-video:before {
    content: "\f87c";
}

.icon-remove-format:before {
    content: "\f87d";
}

.icon-ion-mic-c:before {
    content: "\f87e";
}

.icon-ion-minus-circled:before {
    content: "\f87f";
}

.icon-ion-minus-round:before {
    content: "\f880";
}

.icon-sort-alpha-down-alt:before {
    content: "\f881";
}

.icon-sort-alpha-up-alt:before {
    content: "\f882";
}

.icon-ion-minus:before {
    content: "\f883";
}

.icon-sort-amount-down-alt:before {
    content: "\f884";
}

.icon-sort-amount-up-alt:before {
    content: "\f885";
}

.icon-sort-numeric-down-alt:before {
    content: "\f886";
}

.icon-sort-numeric-up-alt:before {
    content: "\f887";
}

.icon-ion-model-s:before {
    content: "\f888";
}

.icon-ion-monitor:before {
    content: "\f889";
}

.icon-ion-more:before {
    content: "\f88a";
}

.icon-ion-mouse:before {
    content: "\f88b";
}

.icon-ion-music-note:before {
    content: "\f88c";
}

.icon-ion-navicon-round:before {
    content: "\f88d";
}

.icon-ion-navicon:before {
    content: "\f88e";
}

.icon-ion-navigate:before {
    content: "\f88f";
}

.icon-ion-network:before {
    content: "\f890";
}

.icon-spell-check1:before {
    content: "\f891";
}

.icon-ion-no-smoking:before {
    content: "\f892";
}

.icon-ion-nuclear:before {
    content: "\f893";
}

.icon-ion-outlet:before {
    content: "\f894";
}

.icon-ion-paintbrush:before {
    content: "\f895";
}

.icon-ion-paintbucket:before {
    content: "\f896";
}

.icon-voicemail:before {
    content: "\f897";
}

.icon-ion-paper-airplane:before {
    content: "\f898";
}

.icon-ion-paperclip:before {
    content: "\f899";
}

.icon-ion-pause:before {
    content: "\f89a";
}

.icon-ion-person-add:before {
    content: "\f89b";
}

.icon-ion-person-stalker:before {
    content: "\f89c";
}

.icon-ion-person:before {
    content: "\f89d";
}

.icon-ion-pie-graph:before {
    content: "\f89e";
}

.icon-ion-pin:before {
    content: "\f89f";
}

.icon-ion-pinpoint:before {
    content: "\f8a0";
}

.icon-ion-pizza:before {
    content: "\f8a1";
}

.icon-ion-plane:before {
    content: "\f8a2";
}

.icon-ion-planet:before {
    content: "\f8a3";
}

.icon-ion-play:before {
    content: "\f8a4";
}

.icon-ion-playstation:before {
    content: "\f8a5";
}

.icon-ion-plus-circled:before {
    content: "\f8a6";
}

.icon-ion-plus-round:before {
    content: "\f8a7";
}

.icon-ion-plus:before {
    content: "\f8a8";
}

.icon-ion-podium:before {
    content: "\f8a9";
}

.icon-ion-pound:before {
    content: "\f8aa";
}

.icon-ion-power:before {
    content: "\f8ab";
}

.icon-ion-pricetag:before {
    content: "\f8ac";
}

.icon-ion-pricetags:before {
    content: "\f8ad";
}

.icon-ion-printer:before {
    content: "\f8ae";
}

.icon-ion-pull-request:before {
    content: "\f8af";
}

.icon-ion-qr-scanner:before {
    content: "\f8b0";
}

.icon-ion-quote:before {
    content: "\f8b1";
}

.icon-ion-radio-waves:before {
    content: "\f8b2";
}

.icon-ion-record:before {
    content: "\f8b3";
}

.icon-ion-refresh:before {
    content: "\f8b4";
}

.icon-ion-reply-all:before {
    content: "\f8b5";
}

.icon-ion-reply:before {
    content: "\f8b6";
}

.icon-ion-ribbon-a:before {
    content: "\f8b7";
}

.icon-ion-ribbon-b:before {
    content: "\f8b8";
}

.icon-ion-sad-outline:before {
    content: "\f8b9";
}

.icon-ion-sad:before {
    content: "\f8ba";
}

.icon-ion-scissors:before {
    content: "\f8bb";
}

.icon-ion-search:before {
    content: "\f8bc";
}

.icon-ion-settings:before {
    content: "\f8bd";
}

.icon-ion-share:before {
    content: "\f8be";
}

.icon-ion-shuffle:before {
    content: "\f8bf";
}

.icon-ion-skip-backward:before {
    content: "\f8c0";
}

.icon-ion-skip-forward:before {
    content: "\f8c1";
}

.icon-ion-social-android-outline:before {
    content: "\f8c2";
}

.icon-ion-social-android:before {
    content: "\f8c3";
}

.icon-ion-social-angular-outline:before {
    content: "\f8c4";
}

.icon-ion-social-angular:before {
    content: "\f8c5";
}

.icon-ion-social-apple-outline:before {
    content: "\f8c6";
}

.icon-ion-social-apple:before {
    content: "\f8c7";
}

.icon-ion-social-bitcoin-outline:before {
    content: "\f8c8";
}

.icon-ion-social-bitcoin:before {
    content: "\f8c9";
}

.icon-ion-social-buffer-outline:before {
    content: "\f8ca";
}

.icon-ion-social-buffer:before {
    content: "\f8cb";
}

.icon-ion-social-chrome-outline:before {
    content: "\f8cc";
}

.icon-ion-social-chrome:before {
    content: "\f8cd";
}

.icon-ion-social-codepen-outline:before {
    content: "\f8ce";
}

.icon-ion-social-codepen:before {
    content: "\f8cf";
}

.icon-ion-social-css3-outline:before {
    content: "\f8d0";
}

.icon-ion-social-css3:before {
    content: "\f8d1";
}

.icon-ion-social-designernews-outline:before {
    content: "\f8d2";
}

.icon-ion-social-designernews:before {
    content: "\f8d3";
}

.icon-ion-social-dribbble-outline:before {
    content: "\f8d4";
}

.icon-ion-social-dribbble:before {
    content: "\f8d5";
}

.icon-ion-social-dropbox-outline:before {
    content: "\f8d6";
}

.icon-ion-social-dropbox:before {
    content: "\f8d7";
}

.icon-ion-social-euro-outline:before {
    content: "\f8d8";
}

.icon-ion-social-euro:before {
    content: "\f8d9";
}

.icon-ion-social-facebook-outline:before {
    content: "\f8da";
}

.icon-ion-social-facebook:before {
    content: "\f8db";
}

.icon-ion-social-foursquare-outline:before {
    content: "\f8dc";
}

.icon-ion-social-foursquare:before {
    content: "\f8dd";
}

.icon-ion-social-freebsd-devil:before {
    content: "\f8de";
}

.icon-ion-social-github-outline:before {
    content: "\f8df";
}

.icon-ion-social-github:before {
    content: "\f8e0";
}

.icon-ion-social-google-outline:before {
    content: "\f8e1";
}

.icon-ion-social-google:before {
    content: "\f8e2";
}

.icon-ion-social-googleplus-outline:before {
    content: "\f8e3";
}

.icon-ion-social-googleplus:before {
    content: "\f8e4";
}

.icon-ion-social-hackernews-outline:before {
    content: "\f8e5";
}

.icon-ion-social-hackernews:before {
    content: "\f8e6";
}

.icon-ion-social-html5-outline:before {
    content: "\f8e7";
}

.icon-ion-social-html5:before {
    content: "\f8e8";
}

.icon-ion-social-instagram-outline:before {
    content: "\f8e9";
}

.icon-ion-social-instagram:before {
    content: "\f8ea";
}

.icon-ion-social-javascript-outline:before {
    content: "\f8eb";
}

.icon-ion-social-javascript:before {
    content: "\f8ec";
}

.icon-ion-social-linkedin-outline:before {
    content: "\f8ed";
}

.icon-ion-social-linkedin:before {
    content: "\f8ee";
}

.icon-ion-social-markdown:before {
    content: "\f8ef";
}

.icon-ion-social-nodejs:before {
    content: "\f8f0";
}

.icon-ion-social-octocat:before {
    content: "\f8f1";
}

.icon-ion-social-pinterest-outline:before {
    content: "\f8f2";
}

.icon-ion-social-pinterest:before {
    content: "\f8f3";
}

.icon-ion-social-python:before {
    content: "\f8f4";
}

.icon-ion-social-reddit-outline:before {
    content: "\f8f5";
}

.icon-ion-social-reddit:before {
    content: "\f8f6";
}

.icon-ion-social-rss-outline:before {
    content: "\f8f7";
}

.icon-ion-social-rss:before {
    content: "\f8f8";
}

.icon-ion-social-sass:before {
    content: "\f8f9";
}

.icon-ion-social-skype-outline:before {
    content: "\f8fa";
}

.icon-ion-social-skype:before {
    content: "\f8fb";
}

.icon-ion-social-snapchat-outline:before {
    content: "\f8fc";
}

.icon-ion-social-snapchat:before {
    content: "\f8fd";
}

.icon-ion-social-tumblr-outline:before {
    content: "\f8fe";
}

.icon-ion-social-tumblr:before {
    content: "\f8ff";
}

.icon-ion-social-tux:before {
    content: "\f900";
}

.icon-ion-social-twitch-outline:before {
    content: "\f901";
}

.icon-ion-social-twitch:before {
    content: "\f902";
}

.icon-ion-social-twitter-outline:before {
    content: "\f903";
}

.icon-ion-social-twitter:before {
    content: "\f904";
}

.icon-ion-social-usd-outline:before {
    content: "\f905";
}

.icon-ion-social-usd:before {
    content: "\f906";
}

.icon-ion-social-vimeo-outline:before {
    content: "\f907";
}

.icon-ion-social-vimeo:before {
    content: "\f908";
}

.icon-ion-social-whatsapp-outline:before {
    content: "\f909";
}

.icon-ion-social-whatsapp:before {
    content: "\f90a";
}

.icon-ion-social-windows-outline:before {
    content: "\f90b";
}

.icon-ion-social-windows:before {
    content: "\f90c";
}

.icon-ion-social-wordpress-outline:before {
    content: "\f90d";
}

.icon-ion-social-wordpress:before {
    content: "\f90e";
}

.icon-ion-social-yahoo-outline:before {
    content: "\f90f";
}

.icon-ion-social-yahoo:before {
    content: "\f910";
}

.icon-ion-social-yen-outline:before {
    content: "\f911";
}

.icon-ion-social-yen:before {
    content: "\f912";
}

.icon-ion-social-youtube-outline:before {
    content: "\f913";
}

.icon-ion-social-youtube:before {
    content: "\f914";
}

.icon-ion-soup-can-outline:before {
    content: "\f915";
}

.icon-ion-soup-can:before {
    content: "\f916";
}

.icon-ion-speakerphone:before {
    content: "\f917";
}

.icon-ion-speedometer:before {
    content: "\f918";
}

.icon-ion-spoon:before {
    content: "\f919";
}

.icon-ion-star:before {
    content: "\f91a";
}

.icon-ion-stats-bars:before {
    content: "\f91b";
}

.icon-ion-steam:before {
    content: "\f91c";
}

.icon-ion-stop:before {
    content: "\f91d";
}

.icon-ion-thermometer:before {
    content: "\f91e";
}

.icon-ion-thumbsdown:before {
    content: "\f91f";
}

.icon-ion-thumbsup:before {
    content: "\f920";
}

.icon-ion-toggle-filled:before {
    content: "\f921";
}

.icon-ion-toggle:before {
    content: "\f922";
}

.icon-ion-transgender:before {
    content: "\f923";
}

.icon-ion-trash-a:before {
    content: "\f924";
}

.icon-ion-trash-b:before {
    content: "\f925";
}

.icon-ion-trophy:before {
    content: "\f926";
}

.icon-ion-tshirt-outline:before {
    content: "\f927";
}

.icon-ion-tshirt:before {
    content: "\f928";
}

.icon-ion-umbrella:before {
    content: "\f929";
}

.icon-ion-university:before {
    content: "\f92a";
}

.icon-ion-unlocked:before {
    content: "\f92b";
}

.icon-ion-upload:before {
    content: "\f92c";
}

.icon-ion-usb:before {
    content: "\f92d";
}

.icon-ion-videocamera:before {
    content: "\f92e";
}

.icon-ion-volume-high:before {
    content: "\f92f";
}

.icon-ion-volume-low:before {
    content: "\f930";
}

.icon-ion-volume-medium:before {
    content: "\f931";
}

.icon-ion-volume-mute:before {
    content: "\f932";
}

.icon-ion-wand:before {
    content: "\f933";
}

.icon-ion-waterdrop:before {
    content: "\f934";
}

.icon-ion-wifi:before {
    content: "\f935";
}

.icon-ion-wineglass:before {
    content: "\f936";
}

.icon-ion-woman:before {
    content: "\f937";
}

.icon-ion-wrench:before {
    content: "\f938";
}

.icon-ion-xbox:before {
    content: "\f939";
}

.icon-twitter-square:before {
    content: "\f93a";
}

.icon-facebook-square:before {
    content: "\f93b";
}

.icon-linkedin1:before {
    content: "\f93c";
}

.icon-github-square:before {
    content: "\f93d";
}

.icon-twitter1:before {
    content: "\f93e";
}

.icon-facebook11:before {
    content: "\f93f";
}

.icon-github11:before {
    content: "\f940";
}

.icon-pinterest1:before {
    content: "\f941";
}

.icon-pinterest-square:before {
    content: "\f942";
}

.icon-google-plus-square:before {
    content: "\f943";
}

.icon-google-plus-g:before {
    content: "\f944";
}

.icon-linkedin-in:before {
    content: "\f945";
}

.icon-github-alt:before {
    content: "\f946";
}

.icon-maxcdn1:before {
    content: "\f947";
}

.icon-html51:before {
    content: "\f948";
}

.icon-css311:before {
    content: "\f949";
}

.icon-btc1:before {
    content: "\f94a";
}

.icon-youtube1:before {
    content: "\f94b";
}

.icon-xing11:before {
    content: "\f94c";
}

.icon-xing-square:before {
    content: "\f94d";
}

.icon-dropbox1:before {
    content: "\f94e";
}

.icon-stack-overflow:before {
    content: "\f94f";
}

.icon-instagram1:before {
    content: "\f950";
}

.icon-flickr1:before {
    content: "\f951";
}

.icon-adn1:before {
    content: "\f952";
}

.icon-bitbucket:before {
    content: "\f953";
}

.icon-tumblr1:before {
    content: "\f954";
}

.icon-tumblr-square:before {
    content: "\f955";
}

.icon-apple1:before {
    content: "\f956";
}

.icon-windows11:before {
    content: "\f957";
}

.icon-android1:before {
    content: "\f958";
}

.icon-linux1:before {
    content: "\f959";
}

.icon-dribbble:before {
    content: "\f95a";
}

.icon-skype11:before {
    content: "\f95b";
}

.icon-foursquare11:before {
    content: "\f95c";
}

.icon-trello11:before {
    content: "\f95d";
}

.icon-gratipay:before {
    content: "\f95e";
}

.icon-vk1:before {
    content: "\f95f";
}

.icon-weibo1:before {
    content: "\f960";
}

.icon-renren1:before {
    content: "\f961";
}

.icon-pagelines:before {
    content: "\f962";
}

.icon-stack-exchange:before {
    content: "\f963";
}

.icon-vimeo-square:before {
    content: "\f964";
}

.icon-slack:before {
    content: "\f965";
}

.icon-wordpress1:before {
    content: "\f966";
}

.icon-openid:before {
    content: "\f967";
}

.icon-yahoo:before {
    content: "\f968";
}

.icon-google:before {
    content: "\f969";
}

.icon-reddit1:before {
    content: "\f96a";
}

.icon-reddit-square:before {
    content: "\f96b";
}

.icon-stumbleupon-circle:before {
    content: "\f96c";
}

.icon-stumbleupon:before {
    content: "\f96d";
}

.icon-delicious:before {
    content: "\f96e";
}

.icon-digg:before {
    content: "\f96f";
}

.icon-pied-piper-pp:before {
    content: "\f970";
}

.icon-pied-piper-alt:before {
    content: "\f971";
}

.icon-drupal:before {
    content: "\f972";
}

.icon-joomla:before {
    content: "\f973";
}

.icon-behance:before {
    content: "\f974";
}

.icon-behance-square:before {
    content: "\f975";
}

.icon-steam:before {
    content: "\f976";
}

.icon-steam-square:before {
    content: "\f977";
}

.icon-spotify:before {
    content: "\f978";
}

.icon-deviantart:before {
    content: "\f979";
}

.icon-soundcloud1:before {
    content: "\f97a";
}

.icon-vine:before {
    content: "\f97b";
}

.icon-codepen1:before {
    content: "\f97c";
}

.icon-jsfiddle:before {
    content: "\f97d";
}

.icon-rebel:before {
    content: "\f97e";
}

.icon-empire:before {
    content: "\f97f";
}

.icon-git-square:before {
    content: "\f980";
}

.icon-git1:before {
    content: "\f981";
}

.icon-hacker-news:before {
    content: "\f982";
}

.icon-tencent-weibo:before {
    content: "\f983";
}

.icon-qq:before {
    content: "\f984";
}

.icon-weixin:before {
    content: "\f985";
}

.icon-slideshare:before {
    content: "\f986";
}

.icon-twitch1:before {
    content: "\f987";
}

.icon-yelp1:before {
    content: "\f988";
}

.icon-paypal1:before {
    content: "\f989";
}

.icon-google-wallet:before {
    content: "\f98a";
}

.icon-cc-visa:before {
    content: "\f98b";
}

.icon-cc-mastercard:before {
    content: "\f98c";
}

.icon-cc-discover:before {
    content: "\f98d";
}

.icon-cc-amex:before {
    content: "\f98e";
}

.icon-cc-paypal:before {
    content: "\f98f";
}

.icon-cc-stripe:before {
    content: "\f990";
}

.icon-lastfm:before {
    content: "\f991";
}

.icon-lastfm-square:before {
    content: "\f992";
}

.icon-ioxhost:before {
    content: "\f993";
}

.icon-angellist:before {
    content: "\f994";
}

.icon-buysellads:before {
    content: "\f995";
}

.icon-connectdevelop:before {
    content: "\f996";
}

.icon-dashcube:before {
    content: "\f997";
}

.icon-forumbee:before {
    content: "\f998";
}

.icon-leanpub:before {
    content: "\f999";
}

.icon-sellsy:before {
    content: "\f99a";
}

.icon-shirtsinbulk:before {
    content: "\f99b";
}

.icon-simplybuilt:before {
    content: "\f99c";
}

.icon-skyatlas:before {
    content: "\f99d";
}

.icon-pinterest-p:before {
    content: "\f99e";
}

.icon-whatsapp:before {
    content: "\f99f";
}

.icon-viacoin:before {
    content: "\f9a0";
}

.icon-medium:before {
    content: "\f9a1";
}

.icon-y-combinator:before {
    content: "\f9a2";
}

.icon-optin-monster:before {
    content: "\f9a3";
}

.icon-opencart:before {
    content: "\f9a4";
}

.icon-expeditedssl:before {
    content: "\f9a5";
}

.icon-cc-jcb:before {
    content: "\f9a6";
}

.icon-cc-diners-club:before {
    content: "\f9a7";
}

.icon-creative-commons:before {
    content: "\f9a8";
}

.icon-gg:before {
    content: "\f9a9";
}

.icon-gg-circle:before {
    content: "\f9aa";
}

.icon-tripadvisor:before {
    content: "\f9ab";
}

.icon-odnoklassniki:before {
    content: "\f9ac";
}

.icon-odnoklassniki-square:before {
    content: "\f9ad";
}

.icon-get-pocket:before {
    content: "\f9ae";
}

.icon-wikipedia-w:before {
    content: "\f9af";
}

.icon-safari:before {
    content: "\f9b0";
}

.icon-chrome:before {
    content: "\f9b1";
}

.icon-firefox:before {
    content: "\f9b2";
}

.icon-opera:before {
    content: "\f9b3";
}

.icon-internet-explorer:before {
    content: "\f9b4";
}

.icon-contao:before {
    content: "\f9b5";
}

.icon-500px:before {
    content: "\f9b6";
}

.icon-amazon:before {
    content: "\f9b7";
}

.icon-houzz:before {
    content: "\f9b8";
}

.icon-vimeo-v:before {
    content: "\f9b9";
}

.icon-black-tie:before {
    content: "\f9ba";
}

.icon-fonticons:before {
    content: "\f9bb";
}

.icon-reddit-alien:before {
    content: "\f9bc";
}

.icon-edge:before {
    content: "\f9bd";
}

.icon-codiepie:before {
    content: "\f9be";
}

.icon-modx:before {
    content: "\f9bf";
}

.icon-fort-awesome:before {
    content: "\f9c0";
}

.icon-usb:before {
    content: "\f9c1";
}

.icon-product-hunt:before {
    content: "\f9c2";
}

.icon-mixcloud:before {
    content: "\f9c3";
}

.icon-scribd:before {
    content: "\f9c4";
}

.icon-bluetooth:before {
    content: "\f9c5";
}

.icon-bluetooth-b:before {
    content: "\f9c6";
}

.icon-gitlab:before {
    content: "\f9c7";
}

.icon-wpbeginner:before {
    content: "\f9c8";
}

.icon-wpforms:before {
    content: "\f9c9";
}

.icon-envira:before {
    content: "\f9ca";
}

.icon-glide:before {
    content: "\f9cb";
}

.icon-glide-g:before {
    content: "\f9cc";
}

.icon-viadeo:before {
    content: "\f9cd";
}

.icon-viadeo-square:before {
    content: "\f9ce";
}

.icon-snapchat:before {
    content: "\f9cf";
}

.icon-snapchat-ghost:before {
    content: "\f9d0";
}

.icon-snapchat-square:before {
    content: "\f9d1";
}

.icon-pied-piper:before {
    content: "\f9d2";
}

.icon-first-order:before {
    content: "\f9d3";
}

.icon-yoast:before {
    content: "\f9d4";
}

.icon-themeisle:before {
    content: "\f9d5";
}

.icon-google-plus:before {
    content: "\f9d6";
}

.icon-font-awesome:before {
    content: "\f9d7";
}

.icon-linode:before {
    content: "\f9d8";
}

.icon-quora:before {
    content: "\f9d9";
}

.icon-free-code-camp:before {
    content: "\f9da";
}

.icon-telegram:before {
    content: "\f9db";
}

.icon-bandcamp:before {
    content: "\f9dc";
}

.icon-grav:before {
    content: "\f9dd";
}

.icon-etsy:before {
    content: "\f9de";
}

.icon-imdb:before {
    content: "\f9df";
}

.icon-ravelry:before {
    content: "\f9e0";
}

.icon-sellcast:before {
    content: "\f9e1";
}

.icon-superpowers:before {
    content: "\f9e2";
}

.icon-wpexplorer:before {
    content: "\f9e3";
}

.icon-meetup:before {
    content: "\f9e4";
}

.icon-font-awesome-alt:before {
    content: "\f9e5";
}

.icon-accessible-icon:before {
    content: "\f9e6";
}

.icon-accusoft:before {
    content: "\f9e7";
}

.icon-adversal:before {
    content: "\f9e8";
}

.icon-affiliatetheme:before {
    content: "\f9e9";
}

.icon-algolia:before {
    content: "\f9ea";
}

.icon-amilia:before {
    content: "\f9eb";
}

.icon-angrycreative:before {
    content: "\f9ec";
}

.icon-app-store:before {
    content: "\f9ed";
}

.icon-app-store-ios:before {
    content: "\f9ee";
}

.icon-apper:before {
    content: "\f9ef";
}

.icon-asymmetrik:before {
    content: "\f9f0";
}

.icon-audible:before {
    content: "\f9f1";
}

.icon-avianex:before {
    content: "\f9f2";
}

.icon-aws:before {
    content: "\f9f3";
}

.icon-bimobject:before {
    content: "\f9f4";
}

.icon-bitcoin:before {
    content: "\f9f5";
}

.icon-bity:before {
    content: "\f9f6";
}

.icon-blackberry:before {
    content: "\f9f7";
}

.icon-blogger:before {
    content: "\f9f8";
}

.icon-blogger-b:before {
    content: "\f9f9";
}

.icon-buromobelexperte:before {
    content: "\f9fa";
}

.icon-centercode:before {
    content: "\f9fb";
}

.icon-cloudscale:before {
    content: "\f9fc";
}

.icon-cloudsmith:before {
    content: "\f9fd";
}

.icon-cloudversify:before {
    content: "\f9fe";
}

.icon-cpanel:before {
    content: "\f9ff";
}

.icon-css3-alt:before {
    content: "\fa00";
}

.icon-cuttlefish:before {
    content: "\fa01";
}

.icon-d-and-d:before {
    content: "\fa02";
}

.icon-deploydog:before {
    content: "\fa03";
}

.icon-deskpro:before {
    content: "\fa04";
}

.icon-digital-ocean:before {
    content: "\fa05";
}

.icon-discord:before {
    content: "\fa06";
}

.icon-discourse:before {
    content: "\fa07";
}

.icon-dochub:before {
    content: "\fa08";
}

.icon-docker:before {
    content: "\fa09";
}

.icon-draft2digital:before {
    content: "\fa0a";
}

.icon-dribbble-square:before {
    content: "\fa0b";
}

.icon-dyalog:before {
    content: "\fa0c";
}

.icon-earlybirds:before {
    content: "\fa0d";
}

.icon-erlang:before {
    content: "\fa0e";
}

.icon-facebook-f:before {
    content: "\fa0f";
}

.icon-facebook-messenger:before {
    content: "\fa10";
}

.icon-firstdraft:before {
    content: "\fa11";
}

.icon-fonticons-fi:before {
    content: "\fa12";
}

.icon-fort-awesome-alt:before {
    content: "\fa13";
}

.icon-freebsd:before {
    content: "\fa14";
}

.icon-gitkraken:before {
    content: "\fa15";
}

.icon-gofore:before {
    content: "\fa16";
}

.icon-goodreads:before {
    content: "\fa17";
}

.icon-goodreads-g:before {
    content: "\fa18";
}

.icon-google-drive1:before {
    content: "\fa19";
}

.icon-google-play:before {
    content: "\fa1a";
}

.icon-gripfire:before {
    content: "\fa1b";
}

.icon-grunt:before {
    content: "\fa1c";
}

.icon-gulp:before {
    content: "\fa1d";
}

.icon-hacker-news-square:before {
    content: "\fa1e";
}

.icon-hire-a-helper:before {
    content: "\fa1f";
}

.icon-hotjar:before {
    content: "\fa20";
}

.icon-hubspot:before {
    content: "\fa21";
}

.icon-itunes:before {
    content: "\fa22";
}

.icon-itunes-note:before {
    content: "\fa23";
}

.icon-jenkins:before {
    content: "\fa24";
}

.icon-joget:before {
    content: "\fa25";
}

.icon-js:before {
    content: "\fa26";
}

.icon-js-square:before {
    content: "\fa27";
}

.icon-keycdn:before {
    content: "\fa28";
}

.icon-kickstarter:before {
    content: "\fa29";
}

.icon-kickstarter-k:before {
    content: "\fa2a";
}

.icon-laravel:before {
    content: "\fa2b";
}

.icon-line:before {
    content: "\fa2c";
}

.icon-lyft:before {
    content: "\fa2d";
}

.icon-magento:before {
    content: "\fa2e";
}

.icon-medapps:before {
    content: "\fa2f";
}

.icon-medium-m:before {
    content: "\fa30";
}

.icon-medrt:before {
    content: "\fa31";
}

.icon-microsoft:before {
    content: "\fa32";
}

.icon-mix:before {
    content: "\fa33";
}

.icon-mizuni:before {
    content: "\fa34";
}

.icon-monero:before {
    content: "\fa35";
}

.icon-napster:before {
    content: "\fa36";
}

.icon-node-js:before {
    content: "\fa37";
}

.icon-npm1:before {
    content: "\fa38";
}

.icon-ns8:before {
    content: "\fa39";
}

.icon-nutritionix:before {
    content: "\fa3a";
}

.icon-page4:before {
    content: "\fa3b";
}

.icon-palfed:before {
    content: "\fa3c";
}

.icon-patreon:before {
    content: "\fa3d";
}

.icon-periscope:before {
    content: "\fa3e";
}

.icon-phabricator:before {
    content: "\fa3f";
}

.icon-phoenix-framework:before {
    content: "\fa40";
}

.icon-playstation:before {
    content: "\fa41";
}

.icon-pushed:before {
    content: "\fa42";
}

.icon-python:before {
    content: "\fa43";
}

.icon-red-river:before {
    content: "\fa44";
}

.icon-wpressr:before {
    content: "\fa45";
}

.icon-replyd:before {
    content: "\fa46";
}

.icon-resolving:before {
    content: "\fa47";
}

.icon-rocketchat:before {
    content: "\fa48";
}

.icon-rockrms:before {
    content: "\fa49";
}

.icon-schlix:before {
    content: "\fa4a";
}

.icon-searchengin:before {
    content: "\fa4b";
}

.icon-servicestack:before {
    content: "\fa4c";
}

.icon-sistrix:before {
    content: "\fa4d";
}

.icon-slack-hash:before {
    content: "\fa4e";
}

.icon-speakap:before {
    content: "\fa4f";
}

.icon-staylinked:before {
    content: "\fa50";
}

.icon-steam-symbol:before {
    content: "\fa51";
}

.icon-sticker-mule:before {
    content: "\fa52";
}

.icon-studiovinari:before {
    content: "\fa53";
}

.icon-supple:before {
    content: "\fa54";
}

.icon-telegram-plane:before {
    content: "\fa55";
}

.icon-uber:before {
    content: "\fa56";
}

.icon-uikit:before {
    content: "\fa57";
}

.icon-uniregistry:before {
    content: "\fa58";
}

.icon-untappd:before {
    content: "\fa59";
}

.icon-ussunnah:before {
    content: "\fa5a";
}

.icon-vaadin:before {
    content: "\fa5b";
}

.icon-viber:before {
    content: "\fa5c";
}

.icon-vimeo1:before {
    content: "\fa5d";
}

.icon-vnv:before {
    content: "\fa5e";
}

.icon-whatsapp-square:before {
    content: "\fa5f";
}

.icon-whmcs:before {
    content: "\fa60";
}

.icon-wordpress-simple:before {
    content: "\fa61";
}

.icon-xbox:before {
    content: "\fa62";
}

.icon-yandex:before {
    content: "\fa63";
}

.icon-yandex-international:before {
    content: "\fa64";
}

.icon-apple-pay:before {
    content: "\fa65";
}

.icon-cc-apple-pay:before {
    content: "\fa66";
}

.icon-fly:before {
    content: "\fa67";
}

.icon-node:before {
    content: "\fa68";
}

.icon-osi:before {
    content: "\fa69";
}

.icon-react:before {
    content: "\fa6a";
}

.icon-autoprefixer:before {
    content: "\fa6b";
}

.icon-less:before {
    content: "\fa6c";
}

.icon-sass:before {
    content: "\fa6d";
}

.icon-vuejs:before {
    content: "\fa6e";
}

.icon-angular:before {
    content: "\fa6f";
}

.icon-aviato:before {
    content: "\fa70";
}

.icon-ember:before {
    content: "\fa71";
}

.icon-font-awesome-flag:before {
    content: "\fa72";
}

.icon-gitter:before {
    content: "\fa73";
}

.icon-hooli:before {
    content: "\fa74";
}

.icon-strava:before {
    content: "\fa75";
}

.icon-stripe:before {
    content: "\fa76";
}

.icon-stripe-s:before {
    content: "\fa77";
}

.icon-typo3:before {
    content: "\fa78";
}

.icon-amazon-pay:before {
    content: "\fa79";
}

.icon-cc-amazon-pay:before {
    content: "\fa7a";
}

.icon-ethereum:before {
    content: "\fa7b";
}

.icon-korvue:before {
    content: "\fa7c";
}

.icon-elementor:before {
    content: "\fa7d";
}

.icon-youtube-square:before {
    content: "\fa7e";
}

.icon-flipboard:before {
    content: "\fa7f";
}

.icon-hips:before {
    content: "\fa80";
}

.icon-php:before {
    content: "\fa81";
}

.icon-quinscape:before {
    content: "\fa82";
}

.icon-readme:before {
    content: "\fa83";
}

.icon-java:before {
    content: "\fa84";
}

.icon-pied-piper-hat:before {
    content: "\fa85";
}

.icon-creative-commons-by:before {
    content: "\fa86";
}

.icon-creative-commons-nc:before {
    content: "\fa87";
}

.icon-creative-commons-nc-eu:before {
    content: "\fa88";
}

.icon-creative-commons-nc-jp:before {
    content: "\fa89";
}

.icon-creative-commons-nd:before {
    content: "\fa8a";
}

.icon-creative-commons-pd:before {
    content: "\fa8b";
}

.icon-creative-commons-pd-alt:before {
    content: "\fa8c";
}

.icon-creative-commons-remix:before {
    content: "\fa8d";
}

.icon-creative-commons-sa:before {
    content: "\fa8e";
}

.icon-creative-commons-sampling:before {
    content: "\fa8f";
}

.icon-creative-commons-sampling-plus:before {
    content: "\fa90";
}

.icon-creative-commons-share:before {
    content: "\fa91";
}

.icon-creative-commons-zero:before {
    content: "\fa92";
}

.icon-ebay:before {
    content: "\fa93";
}

.icon-keybase:before {
    content: "\fa94";
}

.icon-mastodon:before {
    content: "\fa95";
}

.icon-r-project:before {
    content: "\fa96";
}

.icon-researchgate:before {
    content: "\fa97";
}

.icon-teamspeak:before {
    content: "\fa98";
}

.icon-first-order-alt:before {
    content: "\fa99";
}

.icon-fulcrum:before {
    content: "\fa9a";
}

.icon-galactic-republic:before {
    content: "\fa9b";
}

.icon-galactic-senate:before {
    content: "\fa9c";
}

.icon-jedi-order:before {
    content: "\fa9d";
}

.icon-mandalorian:before {
    content: "\fa9e";
}

.icon-old-republic:before {
    content: "\fa9f";
}

.icon-phoenix-squadron:before {
    content: "\faa0";
}

.icon-sith:before {
    content: "\faa1";
}

.icon-trade-federation:before {
    content: "\faa2";
}

.icon-wolf-pack-battalion:before {
    content: "\faa3";
}

.icon-hornbill:before {
    content: "\faa4";
}

.icon-mailchimp:before {
    content: "\faa5";
}

.icon-megaport:before {
    content: "\faa6";
}

.icon-nimblr:before {
    content: "\faa7";
}

.icon-rev:before {
    content: "\faa8";
}

.icon-shopware:before {
    content: "\faa9";
}

.icon-squarespace:before {
    content: "\faaa";
}

.icon-themeco:before {
    content: "\faab";
}

.icon-weebly:before {
    content: "\faac";
}

.icon-wix:before {
    content: "\faad";
}

.icon-ello:before {
    content: "\faae";
}

.icon-hackerrank:before {
    content: "\faaf";
}

.icon-kaggle:before {
    content: "\fab0";
}

.icon-markdown:before {
    content: "\fab1";
}

.icon-neos:before {
    content: "\fab2";
}

.icon-zhihu:before {
    content: "\fab3";
}

.icon-alipay:before {
    content: "\fab4";
}

.icon-the-red-yeti:before {
    content: "\fab5";
}

.icon-acquisitions-incorporated:before {
    content: "\fab6";
}

.icon-critical-role:before {
    content: "\fab7";
}

.icon-d-and-d-beyond:before {
    content: "\fab8";
}

.icon-dev:before {
    content: "\fab9";
}

.icon-fantasy-flight-games:before {
    content: "\faba";
}

.icon-penny-arcade:before {
    content: "\fabb";
}

.icon-wizards-of-the-coast:before {
    content: "\fabc";
}

.icon-think-peaks:before {
    content: "\fabd";
}

.icon-reacteurope:before {
    content: "\fabe";
}

.icon-adobe:before {
    content: "\fabf";
}

.icon-artstation:before {
    content: "\fac0";
}

.icon-atlassian:before {
    content: "\fac1";
}

.icon-canadian-maple-leaf:before {
    content: "\fac2";
}

.icon-centos:before {
    content: "\fac3";
}

.icon-confluence:before {
    content: "\fac4";
}

.icon-dhl:before {
    content: "\fac5";
}

.icon-diaspora:before {
    content: "\fac6";
}

.icon-fedex:before {
    content: "\fac7";
}

.icon-fedora:before {
    content: "\fac8";
}

.icon-figma:before {
    content: "\fac9";
}

.icon-intercom:before {
    content: "\faca";
}

.icon-invision:before {
    content: "\facb";
}

.icon-jira:before {
    content: "\facc";
}

.icon-mendeley:before {
    content: "\facd";
}

.icon-raspberry-pi:before {
    content: "\face";
}

.icon-redhat:before {
    content: "\facf";
}

.icon-sketch:before {
    content: "\fad0";
}

.icon-sourcetree:before {
    content: "\fad1";
}

.icon-suse:before {
    content: "\fad2";
}

.icon-ubuntu:before {
    content: "\fad3";
}

.icon-ups:before {
    content: "\fad4";
}

.icon-usps:before {
    content: "\fad5";
}

.icon-yarn:before {
    content: "\fad6";
}

.icon-airbnb:before {
    content: "\fad7";
}

.icon-battle-net:before {
    content: "\fad8";
}

.icon-bootstrap:before {
    content: "\fad9";
}

.icon-buffer:before {
    content: "\fada";
}

.icon-chromecast:before {
    content: "\fadb";
}

.icon-evernote:before {
    content: "\fadc";
}

.icon-itch-io:before {
    content: "\fadd";
}

.icon-salesforce:before {
    content: "\fade";
}

.icon-speaker-deck:before {
    content: "\fadf";
}

.icon-symfony:before {
    content: "\fae0";
}

.icon-waze:before {
    content: "\fae1";
}

.icon-yammer:before {
    content: "\fae2";
}

.icon-git-alt:before {
    content: "\fae3";
}

.icon-stackpath:before {
    content: "\fae4";
}
