@import '../../variables.scss';
@import '../../mixins.scss';

.accordion {
    background:white;
    padding: 1rem;

    .wrapper-controls {
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .wrapper-content {
        visibility: hidden;
        height: 1px;
        display: flex;

        &.open {
            visibility: visible;
            height: auto;
        }

        & > * {
            width:47%;
            margin:0 0.5rem;
            overflow: hidden;

            video {
                max-width: 400px;
            }
        }
    }
}

.wrapper-leccion {
    display: flex;
    max-width: 1200px;
    margin: auto;
    justify-content: space-between;

    & > * {
        margin: 1rem;
    }
}

.container-cursos {
    padding: 2rem;
    margin:auto;

    @media(max-width:768px) {
        padding: 0;
    }

    .wrapper-video {
        display:flex;
        background:#B2E7E6;
        overflow: hidden;
        height: 430px;
        position: relative;

        @media(max-width:768px) {
            flex-direction: column;
            background:none;
            height: auto;
        }

        .texto {
            position: absolute;
            max-width: 400px;
            padding: 2rem;
            left: 5%;
            top: 50%;
            @include translate(0, -50%);

            @media(max-width:768px) {
                position: static;
                @include translate(0,0);
                max-width: unset;
            }
        }

        video {
            margin: 0 auto;
            width:100%;
            position:relative;
            left: 25%;
            height: auto;
            padding: 0;
            min-height: 460px;
            top:-5px;

            @media(max-width:768px) {
                left: 0;
            }

            @media(max-width:414px) {
                min-height: unset;
            }

            &.show {
                left: 0;
                background:black;
            }
        }
    }

    h3 {
        text-transform: uppercase;
    }

    .wrapper-leccion {
        display:flex;

        @media(max-width:768px) {
            flex-direction: column;
        }

        .wrapper-left {
            width: 33%;
            background:$color-secundario;
            color:white;
            padding: 2rem;
            border-radius: 10px;

            @media(max-width:768px) {
                width: 90%;
            }

            ol {
                li {
                    margin:1rem 0;
                    color:white;
                }
            }
            h4 {
                color:white;
            }
        }
    }
    

}

.wrapper-cursos-ventajas {
    display: flex;
    margin: 0 auto;
    justify-content: center;


    .wrapper-text {
        padding: 3rem;
        background:transparent;
        background: lightblue;
    }
}

.wrapper-video {
    margin:1rem;
}

.documentos {
    padding: 1rem;
    margin-bottom: 5rem;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;

    h5 {
        margin-bottom: 2rem;
        width: 100%;
    }

    p {
        text-align: left;
    }
    a {
        color:white;
        background:$color-secundario;
        padding: 0.5rem 1rem;
        display: block;
        max-width: 300px;
        margin:0.5rem auto;
        border-radius: 20px;

        &:hover {
            color:white;
        }
    }

    i {
        color:#FF2116;
        font-size: 30px;
        margin: 0 1rem;
        vertical-align: middle;
    }
}

.cursos-wrapper {
    display: flex;
    padding: 3rem;

    @media(max-width:768px) {
        padding: 1rem;
        flex-direction: column;
    }

    .listado {
        border:1px solid $color-primario;
        padding: 1rem;
        width:30%;
        text-align: left;
        border-radius: 30px;

        h2 {
            margin-bottom: 2rem;
        }

        @media(max-width:768px) {
            width: 100%;
        }
        
        .card-listado {
            height: 40px;
            overflow: hidden;

            h4 {
                font-size: 1.2rem;
                cursor: pointer;
            }

            &.open {
                height: auto;
                @include transition(all, 1s, ease-in-out);
            }
        }
    }

    .video-wrapper {
        text-align: center;
        width: 70%;

        video {
            max-width: 800px;
        }

        @media(max-width:768px) {
            width: 100%;

            video {
                max-width: 325px;
            }
        }
    }
}

.leccion {
    padding: 1rem;
    margin: 0.5rem 0;
    border-radius: 15px;
    font-weight: 800;
    color:$color-secundario;
    cursor: pointer;

    @media(max-width:768px) {
        margin:0.2rem 0;
        padding: 0.5rem;
    }

    span {
        background-color: $color-primario;
        padding: 0.5rem;
        border-radius: 10px;
        color:white;
        font-size: 0.8rem;
        font-weight: 400;
        cursor: pointer;

        @media(max-width:768px) {
            display: block;
            max-width: 200px;
            margin-top: 1rem;
        }
    }
}

track {
    background-color: transparent;
}

.extra {
    display: flex;
    margin-top: 5rem;
    justify-content: center;

    @media(max-width:414px) {
        flex-direction: column;
    }

    .wrapper-extra {
        background:lightblue;
        padding: 2rem;
        color:white;
        max-width: 500px;
        position: relative;

        h4 {
            position: absolute;
            top:-20%;
            color:white;
            font-size: 25px;
            background-color: $color-secundario;
            padding: 1.2rem;
            border-radius: 10px;
        }
    
        p {
            color:$color-secundario;
            font-size: 18px;
        }
    
        ul {
            margin-left: 2rem;
            li {
                color:$color-secundario;
                font-size: 18px;
            }
        }
    
        &.short {
            background:lightblue;
            display: inline-block;
            width: 50%;
    
            h4 {
                top:-28%;
            }
    
            p, li {
                color:$color-secundario;
            }
        }
    
    }
}

.faq {
    padding: 2rem;

    .wrapper-white {
        padding: 2rem;
        background:rgba(0,177,173,0.3);
        max-width: 1200px;
        margin:2rem auto;
        border-radius: 15px;

        @media(max-width:414px) {
            padding: 1rem;
        }
    }
}

.tachado {
    text-decoration: line-through;
    font-weight: 800;
}



