@import '../../variables.scss';
@import '../../mixins.scss';

.sumario {
    padding: 2rem;
    align-self: center;

}


h4 {
    padding: 1rem;
    align-self: center;
    overflow: hidden;
    font-size: 1.5rem;
    margin: 1rem auto;
    padding:0 0.5rem;
    text-transform:uppercase;
    // background: rgba($color-primario, 0.3);
}

h1 {
    text-align: center;
    font-size: 50px!important;
}

.sobremi-wrapper {
    padding: 3rem;

    @media(max-width:768px) {
        flex-direction: column;
        padding: 0.5rem;
    }

    h1 {
        width: 100%;
    }

    h2 {
        background-color:$color-secundario;
        color:white;
        max-width: 500px;
        padding: 0.2rem 0.5rem;
        font-size: 45px!important;
    }
}



.foto-about {
    @media(max-width:768px) {
        text-align: center;
    }

    img {
        width: 100%;
        min-width: 500px;

        @media(max-width:768px) {
            min-width: unset;
            max-width: 550px;
            margin: 0 auto;
        }
    }
}

.text-about {
    padding: 0 3rem;
    margin-left: 3rem;

    @media(max-width:768px) {
        padding: 1rem;
        margin-left: 0;
    }

    p {
        text-align: justify;
        margin-right: 1rem;
        padding: 0.5rem 0;
        line-height: 1.8rem;
        margin-bottom: 0;
        font-weight: 400;

        @media(max-width:768px) {
            text-align: left;
        }

        span {
            font-size: 25px;
            display: block;
            max-width: 350px;
            margin-bottom: 1rem;
        }
    }
    

    h2 {
        font-size: 55px;
    }

    .card-formacion {
        padding: 2rem;
        @include box-shadow(-3px, 10px, 20px, rgba(#ccc, 0.3));
        border-left:3px solid $color-primario;
        margin: 2rem 0;
        border-radius: 10px;

        i {
            margin-right: 1rem;
        }

        ul {
            list-style-type: circle;
            margin-left: 2rem;
            margin-top:1.5rem;
    
            li {
                line-height: 40px;
                font-weight: 400;

                span {
                    font-weight: 600;
                    color:$color-secundario;
                    font-weight: 30px;
                }
            }
        }
    }

}


