@import '../../variables.scss';
@import '../../mixins.scss';

.ad {
    max-width: 60%;
    margin: 0 auto;
    background-color: rgba($color-primario, 0.5);
    padding: 2rem;
    border-radius: 20px;
    margin-top: 2rem;
    position: relative;

    i {
        background:white;
        padding: 0.5rem;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        font-size: 30px;
        position: absolute;
        top:0;
        left: 50%;
        text-align: center;
        @include translate(-50%, -50%)
    }
}